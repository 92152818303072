import { React, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";

import {
  registerClient,
  checkAgentAccount,
} from "../../../config/client_api_calls";

import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

//import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";
import { tableLoader } from "../../../components/ContentLoaders/table_loader_register";
import HelperClass from "./../../../config/helperClass";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

import Step1 from "../../../components/Client/ClientRegister/Step1";
import Step2 from "../../../components/Client/ClientRegister/Step2";
import Step3 from "../../../components/Client/ClientRegister/Step3";
import Step4 from "../../../components/Client/ClientRegister/Step4";
import Step5 from "../../../components/Client/ClientRegister/Step5";
import Step6 from "../../../components/Client/ClientRegister/Step6";
const auth = new HelperClass();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      company_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: "",
      errorPass: "",
      errorcPass: "",
      errorName: "",
      errorMessage: "",
      errorCompName: "",
      errorTitle: "",
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: "",
      disableResendBtn: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
      show_form: false,
      resellerData: [],
      tableLoader: true,
      res_accountno: "",
      res_title: "",
      super_agent_accountno: "",
      agent_title: "",
      action_type: "",
      pageError: "",
      res_url: "",
      url_type: "Simple",
      step: 1,
      filer_id: "",
      select_499: false,
      no_of_employes: "",
      service_id: "",
      service_type: "",
      packagesData: [],
      servicesData: [],
      portingData: [],
      miscData: [],
      subscriptio_type: "",
    };
  }

  async componentDidMount() {
    let signup_hash = this.props.match.params.signup_hash;
    let service_id = this.props.match.params.serviceid;
    let servicetype = this.props.match.params.servicetype;
    /*if (servicetype === "package") {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError: "Package based registration is coming soon.",
      });
    } else {*/
    if (signup_hash !== "") {
      //Errors
      //404 and no_reseller
      //404 and no_agent
      let action_type = "client";
      let urlElements = window.location.href.split("/");
      console.log("CUrrent URL: ", urlElements[0] + "//" + urlElements[2]);
      let host_url = urlElements[2];
      if (service_id && service_id !== "") {
        this.setState({
          service_id: service_id,
        });
      }
      if (!servicetype && service_id === "") {
        servicetype = "";
      }
      try {
        let response = await checkAgentAccount({
          signup_hash: signup_hash,
          action_type: action_type,
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          host_url: host_url,
          service_id: service_id,
          servicetype: servicetype,
        });
        console.log("Agent.response.data : ", response.data);
        if (
          response.data.status === 404 ||
          response.data.status === 422 ||
          response.data.errors !== ""
        ) {
          this.setState({
            errorMessage: "There is some error.",
            successMessage: "",
            show_form: false,
            pageError: "",
          });
        }

        if (
          response.data.status === 404 &&
          response.data.message === "no_reseller"
        ) {
          this.setState({
            errorMessage: "",
            pageError:
              "Agent does not exists. Please contact support or try again with correct URL.",
            successMessage: "",
            show_form: false,
          });
        } else if (
          response.data.status === 404 &&
          response.data.message === "no_agent"
        ) {
          this.setState({
            pageError:
              "Agent does not exists. Please contact support or try again with correct URL.",
            errorMessage: "",
            successMessage: "",
            show_form: false,
          });
        } else if (response.data.status === 403) {
          this.setState({
            errorMessage: "There is some error.",
            successMessage: "",
            show_form: false,
            pageError: "",
          });
        } else if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          Cookies.set(
            "restkn__",
            CryptoJS.AES.encrypt(
              signup_hash,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "resacct__",
            CryptoJS.AES.encrypt(
              response.data.res_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "resregtype__",
            CryptoJS.AES.encrypt(
              response.data.action_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "restitle__",
            CryptoJS.AES.encrypt(
              response.data.reseller_title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "agttitle__",
            CryptoJS.AES.encrypt(
              response.data.agent_title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "agtacctno__",
            CryptoJS.AES.encrypt(
              response.data.agent_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "reslogo__",
            CryptoJS.AES.encrypt(
              response.data.reseller_logo,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "ressig__",
            CryptoJS.AES.encrypt(
              response.data.reseller_signature,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "residentifier__",
            CryptoJS.AES.encrypt(
              response.data.reseller_domain_identifier,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "urltype__",
            CryptoJS.AES.encrypt(
              response.data.url_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "clientserviceid__",
            CryptoJS.AES.encrypt(
              service_id,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          Cookies.set(
            "clientservicetype__",
            CryptoJS.AES.encrypt(
              servicetype,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          if (servicetype === "service") {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: response.data.servicesListing,
              service_type: response.data.servicesListing[0].service_type,
              portingData: response.data.portingListing,
              miscData: response.data.miscListing,
              packagesData: [],
              subscriptio_type: servicetype,
            });
          } else if (servicetype === "package") {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: [],
              service_type: "",
              portingData: [],
              miscData: [],
              packagesData: response.data.data,
              subscriptio_type: servicetype,
            });
          } else {
            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              agent_title: response.data.agent_title,
              super_agent_accountno: response.data.agent_accountno,
              action_type: action_type,
              pageError: "",
              servicesData: [],
              service_type: "",
              portingData: [],
              miscData: [],
              packagesData: [],
              subscriptio_type: "",
            });
          }
        }
      } catch (error) {
        this.setState({
          errorMessage: "There is some error.",
          successMessage: "",
          show_form: false,
          pageError: "",
        });
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }
    //}
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("Main Handler: ", name + " - " + value);
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("Main Handler Radio: ", name + " - " + value);
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
    console.log("STEP: ", this.state.step);
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  formSubmit = async () => {
    //e.preventDefault();
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      company_name,
      title,
      filer_id,
      no_of_employes,
      service_id,
      super_agent_accountno,
      res_accountno,
      service_type,
      subscriptio_type,
    } = this.state;
    var is_validated = true;
    if (name === "") {
      is_validated = false;
    }
    if (email === "") {
      is_validated = false;
    }
    if (password === "") {
      is_validated = false;
    }
    if (phone === "") {
      is_validated = false;
    }
    if (company_name === "") {
      is_validated = false;
    }
    /*if (title === "") {
      is_validated = false;
    }
    if (no_of_employes === "") {
      is_validated = false;
    }
    if (service_id === "") {
      is_validated = false;
    }*/

    if (is_validated) {
      try {
        this.setState({
          disabled: true,
        });
        let clientRegisterService = await registerClient({
          name: name,
          email: email,
          password: password,
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          agent_accountno: super_agent_accountno,
          phone: phone,
          comp_name: company_name,
          title: title,
          filer_id: filer_id,
          no_of_employes: no_of_employes,
          service_id: service_id,
          res_accountno: res_accountno,
          service_type: service_type,
          subscriptio_type: subscriptio_type,
        });
        console.log("response.data : ", clientRegisterService.data);
        if (
          clientRegisterService.data.status === 404 ||
          clientRegisterService.data.status === 422 ||
          clientRegisterService.data.errors !== ""
        ) {
          this.setState({
            errorMessage: "Invalid Email OR Password",
            disabled: false,
          });
        }

        if (
          clientRegisterService.data.status === 404 &&
          clientRegisterService.data.message === "Account Already Exists"
        ) {
          this.setState({
            errorMessage: clientRegisterService.data.message,
            disabled: false,
          });
        } else if (
          clientRegisterService.data.status === 200 &&
          clientRegisterService.data.message === "Success"
        ) {
          $("#resendBtn").show();
          $("#signupBtn").hide();

          this.setState({
            successMessage: ` Congrats! Successfully created your profile. Email
                sent to your account. To complete verification
                process please go to your inbox and click on
                verification link in the email.`,
            errorMessage: "",
            fieldsHide: true,
          });
        } else {
          this.setState({
            errorMessage: ` There is some error while creating the account.`,
            successMessage: "",
            fieldsHide: true,
          });
        }
      } catch (error) {
        console.log("catch : ", error);
        this.setState({
          errorMessage: "There is some error while creating the account.",
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please enter the required fields.",
        disabled: false,
      });
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
      });
    }, 8000);
  };

  downloadCsv = async () => {
    console.log("HERE FILE: ");

    /*if (page_id !== "") {
      const reportsData = await getCSVData(
        auth.getAccount(),
        auth.getToken(),
        page_id
      );
      console.log("downloadCsv: ", reportsData.data.data);

      if (
        reportsData.data.status === 403 ||
        reportsData.data.errors === "authentication missing" ||
        reportsData.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (reportsData.data.status === 404) {
        this.setState({
          dErrorMessage: "There is some error.",
        });
        //window.location.replace("/error");
      } else if (
        reportsData.data.status === 200 &&
        reportsData.data.message === "success"
      ) {
        var url = reportsData.data.data;
        const response = await fetch(url);
        const data = await response.text();
        const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
        const blobURL = window.URL.createObjectURL(blob);

        // Create new tag for download file
        const anchor = document.createElement("a");
        anchor.download = page_id;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        // Remove URL.createObjectURL. The browser should not save the reference to the file.
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          URL.revokeObjectURL(blobURL);
        }, 100);
      } else {
        this.setState({
          dErrorMessage: "There is some error.",
        });
      }
    } else {
      this.setState({
        dErrorMessage: "File does not exists",
      });
    }*/
  };

  render() {
    const { step } = this.state;
    const {
      company_name,
      filer_id,
      password,
      confirm_password,
      name,
      email,
      phone,
      title,
      no_of_employes,
      errorMessage,
      successMessage,
      service_id,
      agent_title,
      res_title,
    } = this.state;
    const values = {
      company_name,
      filer_id,
      password,
      confirm_password,
      name,
      email,
      phone,
      title,
      no_of_employes,
      errorMessage,
      successMessage,
      service_id,
      agent_title,
      res_title,
    };

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta name="description" content="Client SignUp" />
            <title>Client SignUp</title>
          </Helmet>
        </HelmetProvider>
        <div
          className="nk-body npc-crypto ui-clean pg-auth"
          id="register_container"
        >
          <div className="nk-app-root">
            <div className="nk-split nk-split-page nk-split-md">
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45 ">
                <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                  <a
                    href="#"
                    className="toggle btn btn-white btn-icon btn-light"
                    data-target="athPromo"
                  >
                    <em className="icon ni ni-info"></em>
                  </a>
                </div>
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="brand-logo pb-5">
                    {auth.getResellerLogo() !== "" ? (
                      <a href="/" className="logo-link">
                        <img
                          className="logo-light logo-img logo-img-lg"
                          src={auth.getResellerLogo()}
                          alt="logo"
                        />
                        <img
                          className="logo-dark logo-img logo-img-lg"
                          src={auth.getResellerLogo()}
                          alt="logo-dark"
                        />
                      </a>
                    ) : (
                      <a href="/" className="logo-link">
                        <img
                          className="logo-light logo-img logo-img-lg"
                          src={LOGO}
                          alt="logo"
                        />
                        <img
                          className="logo-dark logo-img logo-img-lg"
                          src={LOGO}
                          alt="logo-dark"
                        />
                      </a>
                    )}
                  </div>
                  <div className="card1">
                    <div className="container">
                      <div
                        className="row change_logo"
                        style={{ marginTop: "25px", marginLeft: "auto" }}
                      ></div>
                      <h3 className="image-side-txt">
                        {auth.getResellerRegTitle() !== ""
                          ? auth.getResellerRegTitle()
                          : "Number.io"}{" "}
                        Client SignUp
                      </h3>
                      <p style={{ color: "#637381", marginTop: "25px" }}>
                        {" "}
                        This portal provides you access to{" "}
                        {auth.getResellerRegTitle() !== ""
                          ? auth.getResellerRegTitle()
                          : "Number.io"}{" "}
                        service contracts, rates, reports, and product marketing
                        resources you need to establish services. Please visit
                        the main site for other{" "}
                        {auth.getResellerRegTitle() !== ""
                          ? auth.getResellerRegTitle()
                          : "Number.io"}{" "}
                        Business Units.
                      </p>

                      {this.state.packagesData &&
                      this.state.packagesData.length > 0 ? (
                        <div className="card card-bordered card-full">
                          <div className="nk-wg1">
                            <div className="nk-wg1-block">
                              <div className="nk-wg1-img">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 90 90"
                                >
                                  <path
                                    d="M40.74,5.16l38.67,9.23a6,6,0,0,1,4.43,7.22L70.08,80a6,6,0,0,1-7.17,4.46L24.23,75.22A6,6,0,0,1,19.81,68L33.56,9.62A6,6,0,0,1,40.74,5.16Z"
                                    fill="#eff1ff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <path
                                    d="M50.59,6.5,11.18,11.73a6,6,0,0,0-5.13,6.73L13.85,78a6,6,0,0,0,6.69,5.16l39.4-5.23a6,6,0,0,0,5.14-6.73l-7.8-59.49A6,6,0,0,0,50.59,6.5Z"
                                    fill="#eff1ff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <rect
                                    x="15"
                                    y="15"
                                    width="54"
                                    height="70"
                                    rx="6"
                                    ry="6"
                                    fill="#fff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></rect>
                                  <line
                                    x1="42"
                                    y1="77"
                                    x2="58"
                                    y2="77"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></line>
                                  <circle
                                    cx="38"
                                    cy="77"
                                    r="0.5"
                                    fill="#c4cefe"
                                    stroke="#c4cefe"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <line
                                    x1="42"
                                    y1="72"
                                    x2="58"
                                    y2="72"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></line>
                                  <circle
                                    cx="38"
                                    cy="72"
                                    r="0.5"
                                    fill="#c4cefe"
                                    stroke="#c4cefe"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <line
                                    x1="42"
                                    y1="66"
                                    x2="58"
                                    y2="66"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></line>
                                  <circle
                                    cx="38"
                                    cy="66"
                                    r="0.5"
                                    fill="#c4cefe"
                                    stroke="#c4cefe"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <path
                                    d="M46,40l-15-.3V40A15,15,0,1,0,46,25h-.36Z"
                                    fill="#e3e7fe"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <path
                                    d="M42,22A14,14,0,0,0,28,36H42V22"
                                    fill="#e3e7fe"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <polyline
                                    points="33.47 30.07 28.87 23 23 23"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></polyline>
                                  <polyline
                                    points="25 56 35 56 40.14 49"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></polyline>
                                </svg>
                              </div>
                              <div className="nk-wg1-text">
                                <h5 className="title">Selected Package</h5>
                                <p>{this.state.packagesData[0].bundle_title}</p>
                              </div>
                            </div>
                            <div className="nk-wg1-action">
                              <a
                                style={{ cursor: "pointer" }}
                                data-toggle="modal"
                                data-target="#modalFormDetailPackage"
                                className="link"
                              >
                                <span>Package Detail</span>{" "}
                                <em className="icon ni ni-chevron-right"></em>
                              </a>
                            </div>
                          </div>
                          {/* Package DETAILS */}
                          <div
                            className="modal fade zoom"
                            tabIndex="-1"
                            id="modalFormDetailPackage"
                          >
                            <div
                              className="modal-dialog modal-xl modal-dialog-top"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <div
                                    className="modal-title"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <strong>
                                      <em className="icon ni ni-list-round"></em>{" "}
                                      Package Details
                                    </strong>
                                  </div>
                                  <a
                                    href="#"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </a>
                                </div>

                                <div className="modal-body">
                                  {/* NRC DIVS START */}

                                  {this.state.packagesData ? (
                                    <>
                                      <div className="card card-bordered">
                                        <div className="card-inner-group">
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                {
                                                  this.state.packagesData[0]
                                                    .bundle_title
                                                }
                                              </h6>
                                            </div>
                                            <div
                                              className="sp-plan-desc sp-plan-desc-mb"
                                              style={{
                                                marginBottom: "15px",
                                                borderBottom:
                                                  "1px solid rgb(246, 246, 246)",
                                                paddingBottom: "7px",
                                              }}
                                            >
                                              <ul className="row gx-1">
                                                <li className="col-sm-6">
                                                  <p>
                                                    <span className="text-soft">
                                                      Per Month Rate (Charge
                                                      Monthly){" "}
                                                      <Tooltip
                                                        title="Per Month Rate (Charge Monthly)"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {this.state.packagesData[0].per_month_rate.toFixed(
                                                      4
                                                    )}
                                                  </p>
                                                </li>
                                                <li className="col-sm-6">
                                                  <p>
                                                    <span className="text-soft">
                                                      Per Month Rate (Charge
                                                      Yearly)
                                                      <Tooltip
                                                        title="Per Month Rate (Charge Yearly)"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {this.state.packagesData[0].per_year_rate.toFixed(
                                                      4
                                                    )}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-6">
                                                  <p>
                                                    <span className="text-soft">
                                                      Date Added
                                                      <Tooltip
                                                        title="Date Added"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                    </span>{" "}
                                                    {this.state.packagesData[0]
                                                      .date_added !== ""
                                                      ? this.format_date(
                                                          this.state
                                                            .packagesData[0]
                                                            .date_added
                                                        )
                                                      : null}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{ marginTop: "15px" }}>
                                        <h4
                                          style={{
                                            marginTop: "20px",
                                            mrginBottom: "20px",
                                          }}
                                        >
                                          Assigned Services
                                        </h4>
                                        {this.state.packagesData[0]
                                          .bundle_services &&
                                        this.state.packagesData[0]
                                          .bundle_services.length > 0 ? (
                                          <>
                                            {this.state.packagesData[0].bundle_services.map(
                                              (bundle_services, idk) => (
                                                <div
                                                  className="nk-block"
                                                  key={`bundle${idk}`}
                                                >
                                                  <div className="card card-bordered">
                                                    <div className="card-inner">
                                                      <div className="nk-help">
                                                        <div className="nk-help-text">
                                                          <h5>
                                                            {
                                                              bundle_services.service_title
                                                            }
                                                            {"  "}
                                                            <span className="badge badge-dim badge-primary badge-pill">
                                                              {bundle_services.service_type ===
                                                              "did"
                                                                ? "DID Origination"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "tfn"
                                                                ? "Toll Free Origination"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "term"
                                                                ? "DID Termination"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "teams"
                                                                ? "Microsoft Teams UCAAS"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "ivr"
                                                                ? "Voyce Admins"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "tfn_term"
                                                                ? "Toll Free Termination"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "az_term"
                                                                ? "International A-Z Termination"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "live_agent"
                                                                ? "Live Agent"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "sms"
                                                                ? "SMS Inbound / Outbound"
                                                                : ""}
                                                              {bundle_services.service_type ===
                                                              "cnam"
                                                                ? "CNAM"
                                                                : ""}
                                                              {bundle_services.main_type ===
                                                              "ratedeck"
                                                                ? " ( Rate Deck )"
                                                                : ""}
                                                            </span>
                                                          </h5>
                                                          <p className="text-soft"></p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </>
                                        ) : null}
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Package DETAIL */}
                        </div>
                      ) : null}

                      {this.state.servicesData &&
                      this.state.servicesData.length > 0 ? (
                        <div className="card card-bordered card-full">
                          <div className="nk-wg1">
                            <div className="nk-wg1-block">
                              <div className="nk-wg1-img">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 90 90"
                                >
                                  <path
                                    d="M40.74,5.16l38.67,9.23a6,6,0,0,1,4.43,7.22L70.08,80a6,6,0,0,1-7.17,4.46L24.23,75.22A6,6,0,0,1,19.81,68L33.56,9.62A6,6,0,0,1,40.74,5.16Z"
                                    fill="#eff1ff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <path
                                    d="M50.59,6.5,11.18,11.73a6,6,0,0,0-5.13,6.73L13.85,78a6,6,0,0,0,6.69,5.16l39.4-5.23a6,6,0,0,0,5.14-6.73l-7.8-59.49A6,6,0,0,0,50.59,6.5Z"
                                    fill="#eff1ff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <rect
                                    x="15"
                                    y="15"
                                    width="54"
                                    height="70"
                                    rx="6"
                                    ry="6"
                                    fill="#fff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></rect>
                                  <line
                                    x1="42"
                                    y1="77"
                                    x2="58"
                                    y2="77"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></line>
                                  <circle
                                    cx="38"
                                    cy="77"
                                    r="0.5"
                                    fill="#c4cefe"
                                    stroke="#c4cefe"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <line
                                    x1="42"
                                    y1="72"
                                    x2="58"
                                    y2="72"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></line>
                                  <circle
                                    cx="38"
                                    cy="72"
                                    r="0.5"
                                    fill="#c4cefe"
                                    stroke="#c4cefe"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <line
                                    x1="42"
                                    y1="66"
                                    x2="58"
                                    y2="66"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></line>
                                  <circle
                                    cx="38"
                                    cy="66"
                                    r="0.5"
                                    fill="#c4cefe"
                                    stroke="#c4cefe"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <path
                                    d="M46,40l-15-.3V40A15,15,0,1,0,46,25h-.36Z"
                                    fill="#e3e7fe"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <path
                                    d="M42,22A14,14,0,0,0,28,36H42V22"
                                    fill="#e3e7fe"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></path>
                                  <polyline
                                    points="33.47 30.07 28.87 23 23 23"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></polyline>
                                  <polyline
                                    points="25 56 35 56 40.14 49"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  ></polyline>
                                </svg>
                              </div>
                              <div className="nk-wg1-text">
                                <h5 className="title">Selected Service</h5>
                                <p>
                                  {this.state.servicesData[0].service_title}
                                </p>
                              </div>
                            </div>
                            <div className="nk-wg1-action">
                              <a
                                style={{ cursor: "pointer" }}
                                data-toggle="modal"
                                data-target="#modalFormDetail"
                                className="link"
                              >
                                <span>Service Detail</span>{" "}
                                <em className="icon ni ni-chevron-right"></em>
                              </a>
                            </div>
                          </div>
                          {/* SERVICE DETAILS */}
                          <div
                            className="modal fade zoom"
                            tabIndex="-1"
                            id="modalFormDetail"
                          >
                            <div
                              className="modal-dialog modal-xl modal-dialog-top"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <div
                                    className="modal-title"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <strong>
                                      <em className="icon ni ni-list-round"></em>{" "}
                                      Service Details
                                    </strong>
                                  </div>
                                  <a
                                    href="#"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </a>
                                </div>

                                <div className="modal-body">
                                  <br />
                                  {/* NRC DIVS START */}

                                  {this.state.servicesData ? (
                                    <>
                                      <div>
                                        {this.state.servicesData.map(
                                          (services, idx) => (
                                            <div
                                              className="card card-bordered"
                                              key={`servi${idx}`}
                                            >
                                              <div className="card-inner-group">
                                                {services.service_type ===
                                                  "did" ||
                                                services.service_type ===
                                                  "tfn" ||
                                                services.service_type ===
                                                  "teams" ? (
                                                  <div className="card-inner">
                                                    <div className="sp-plan-head">
                                                      <h6 className="title">
                                                        {services.service_title}
                                                        <span className="badge badge-dim badge-primary badge-pill">
                                                          {services.service_type ===
                                                          "did"
                                                            ? "DID Origination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "tfn"
                                                            ? "Toll Free Origination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "term"
                                                            ? "Voice Termination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "teams"
                                                            ? "Microsoft Teams UCAAS"
                                                            : ""}
                                                          {services.service_type ===
                                                          "ivr"
                                                            ? "Voyce Admins"
                                                            : ""}
                                                          {services.service_type ===
                                                          "tfn_term"
                                                            ? "Toll Free Termination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "az_term"
                                                            ? "International A-Z Termination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "live_agent"
                                                            ? "Live Agent"
                                                            : ""}
                                                          {services.service_type ===
                                                          "sms"
                                                            ? "SMS Inbound / Outbound"
                                                            : ""}
                                                        </span>
                                                      </h6>
                                                    </div>

                                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                                      <ul className="row gx-1">
                                                        <li className="col-sm-4">
                                                          <p>
                                                            <span className="text-soft">
                                                              NRC
                                                              <Tooltip
                                                                title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>
                                                            </span>{" "}
                                                            $
                                                            {services.nrc &&
                                                            services.nrc > 0
                                                              ? services.nrc.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}
                                                          </p>
                                                        </li>
                                                        <li className="col-sm-4">
                                                          <p>
                                                            <span className="text-soft">
                                                              MRC{" "}
                                                              <Tooltip
                                                                title="Monthly Recurring Charge - what you pay each month for the service."
                                                                placement="top"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>
                                                            </span>{" "}
                                                            $
                                                            {services.mrc &&
                                                            services.mrc > 0
                                                              ? services.mrc.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}
                                                          </p>
                                                        </li>
                                                        {services.main_type !==
                                                        "ratedeck" ? (
                                                          <li className="col-sm-4">
                                                            <p>
                                                              <span className="text-soft">
                                                                Rate Per Minute{" "}
                                                                <Tooltip
                                                                  title="Rate assigned per minute."
                                                                  placement="top"
                                                                >
                                                                  <em
                                                                    className="icon ni ni-info"
                                                                    style={{
                                                                      color:
                                                                        "#6576ff",
                                                                    }}
                                                                  ></em>
                                                                </Tooltip>
                                                              </span>
                                                              $
                                                              {services.rate &&
                                                              services.rate > 0
                                                                ? services.rate.toFixed(
                                                                    4
                                                                  )
                                                                : "0.00"}
                                                            </p>
                                                          </li>
                                                        ) : null}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                {services.service_type ===
                                                  "term" ||
                                                services.service_type ===
                                                  "ivr" ||
                                                services.service_type ===
                                                  "live_agent" ||
                                                services.service_type ===
                                                  "tfn_term" ||
                                                services.service_type ===
                                                  "az_term" ? (
                                                  <div className="card-inner">
                                                    <div className="sp-plan-head">
                                                      <h6 className="title">
                                                        {services.service_title}{" "}
                                                        <span className="badge badge-dim badge-primary badge-pill">
                                                          {services.service_type ===
                                                          "did"
                                                            ? "DID Origination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "tfn"
                                                            ? "Toll Free Origination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "term"
                                                            ? "DID Termination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "teams"
                                                            ? "Microsoft Teams UCAAS"
                                                            : ""}
                                                          {services.service_type ===
                                                          "ivr"
                                                            ? "Voyce Admins"
                                                            : ""}
                                                          {services.service_type ===
                                                          "tfn_term"
                                                            ? "Toll Free Termination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "az_term"
                                                            ? "International A-Z Termination"
                                                            : ""}
                                                          {services.service_type ===
                                                          "live_agent"
                                                            ? "Live Agent"
                                                            : ""}
                                                          {services.service_type ===
                                                          "sms"
                                                            ? "SMS Inbound / Outbound"
                                                            : ""}
                                                          {services.main_type ===
                                                          "ratedeck"
                                                            ? " ( Rate Deck )"
                                                            : ""}
                                                        </span>
                                                      </h6>
                                                    </div>

                                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                                      <ul className="row gx-1">
                                                        {services.main_type ===
                                                        "ratedeck" ? (
                                                          <>
                                                            {/*<li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Agent Commission
                                                      </span>{" "}
                                                      {services[14]}%
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services[24]}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Download Rate Deck
                                                      </span>{" "}
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </p>
                                                  </li>*/}
                                                          </>
                                                        ) : (
                                                          <li className="col-sm-4">
                                                            <p>
                                                              <span className="text-soft">
                                                                Rate Per Minute{" "}
                                                                <Tooltip
                                                                  title="Rate assigned per minute."
                                                                  placement="top"
                                                                >
                                                                  <em
                                                                    className="icon ni ni-info"
                                                                    style={{
                                                                      color:
                                                                        "#6576ff",
                                                                    }}
                                                                  ></em>
                                                                </Tooltip>
                                                              </span>
                                                              $
                                                              {services.rate &&
                                                              services.rate > 0
                                                                ? services.rate.toFixed(
                                                                    4
                                                                  )
                                                                : "0.00"}
                                                            </p>
                                                          </li>
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                {services.service_type ===
                                                "sms" ? (
                                                  <div className="card-inner">
                                                    <div className="sp-plan-head">
                                                      <h6 className="title">
                                                        {services.service_title}{" "}
                                                        <span className="badge badge-dim badge-primary badge-pill">
                                                          New
                                                        </span>
                                                      </h6>
                                                    </div>

                                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                                      <ul className="row gx-1">
                                                        <li className="col-sm-5">
                                                          <p>
                                                            <span className="text-soft">
                                                              SMS In{" "}
                                                              <Tooltip
                                                                title="Price applied to SMS In Services."
                                                                placement="top"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>
                                                            </span>{" "}
                                                            $
                                                            {services.sms_in &&
                                                            services.sms_in > 0
                                                              ? services.sms_in.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}
                                                          </p>
                                                        </li>
                                                        <li className="col-sm-5">
                                                          <p>
                                                            <span className="text-soft">
                                                              SMS Out{" "}
                                                              <Tooltip
                                                                title="Price applied to SMS Out Services."
                                                                placement="top"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>
                                                            </span>{" "}
                                                            $
                                                            {services.sms_out &&
                                                            services.sms_out > 0
                                                              ? services.sms_out.toFixed(
                                                                  4
                                                                )
                                                              : "0.00"}
                                                          </p>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                {services.service_type ===
                                                  "did" ||
                                                services.service_type ===
                                                  "tfn" ||
                                                services.service_type ===
                                                  "teams" ? (
                                                  <>
                                                    {this.state.portingData &&
                                                    this.state.portingData
                                                      .length > 0 ? (
                                                      <div className="card-inner">
                                                        <div className="sp-plan-head">
                                                          <h6 className="title">
                                                            Porting Rates
                                                          </h6>
                                                        </div>

                                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                                          <ul className="row gx-1">
                                                            <li className="col-sm-5">
                                                              <p>
                                                                <span className="text-soft">
                                                                  Portin Sell
                                                                  Rate
                                                                </span>{" "}
                                                                $
                                                                {this.state.portingData[0].portin_sell_rate.toFixed(
                                                                  4
                                                                )}
                                                              </p>
                                                            </li>
                                                            <li className="col-sm-5">
                                                              <p>
                                                                <span className="text-soft">
                                                                  Portin Sell
                                                                  Rate
                                                                </span>{" "}
                                                                $
                                                                {this.state.portingData[0].portout_sell_rate.toFixed(
                                                                  4
                                                                )}
                                                              </p>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </>
                                                ) : null}
                                                <div className="card-inner">
                                                  {services.main_type !==
                                                  "ratedeck" ? (
                                                    <>
                                                      <div className="sp-plan-head">
                                                        <h6 className="title">
                                                          Cancellation Fee
                                                        </h6>
                                                      </div>
                                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                                        <ul className="row gx-1">
                                                          <li className="col-sm-5">
                                                            <p>
                                                              <span className="text-soft">
                                                                Cancellation Fee
                                                              </span>{" "}
                                                              {services.cancellation_fee &&
                                                              services.cancellation_fee !==
                                                                "" ? (
                                                                <>
                                                                  $
                                                                  {services.cancellation_fee.toFixed(
                                                                    4
                                                                  )}
                                                                </>
                                                              ) : (
                                                                "$0.00"
                                                              )}
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </>
                                                  ) : null}
                                                  {services.main_type ===
                                                  "ratedeck" ? (
                                                    <>
                                                      <div className="sp-plan-head">
                                                        <h6 className="title">
                                                          Rate Deck / Effective
                                                          Date
                                                        </h6>
                                                      </div>
                                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                                        <ul className="row gx-1">
                                                          <li className="col-sm-5">
                                                            <p>
                                                              <span className="text-soft">
                                                                Rate Deck
                                                              </span>{" "}
                                                              {services.rate_deck_file &&
                                                              services.rate_deck_file !==
                                                                "" ? (
                                                                <>
                                                                  <a
                                                                    onClick={() =>
                                                                      this.downloadCsv(
                                                                        services.rate_deck_file
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    className="btn btn-outline-primary"
                                                                  >
                                                                    <em className="icon ni ni-download"></em>{" "}
                                                                    Download
                                                                    Rate Deck
                                                                  </a>
                                                                </>
                                                              ) : null}
                                                            </p>
                                                          </li>
                                                          <li className="col-sm-5">
                                                            <p>
                                                              <span className="text-soft">
                                                                Effective Date
                                                              </span>{" "}
                                                              {services.effective_date &&
                                                              services.effective_date !==
                                                                "" ? (
                                                                <>
                                                                  {this.format_date(
                                                                    services.effective_date
                                                                  )}
                                                                </>
                                                              ) : null}
                                                            </p>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </>
                                                  ) : null}
                                                  {this.state.miscData &&
                                                  this.state.miscData.length >
                                                    0 ? (
                                                    <>
                                                      <div
                                                        className="sp-plan-head"
                                                        style={{
                                                          marginTop: "15px",
                                                          borderTop:
                                                            "1px solid #f6f6f6",
                                                          paddingTop: "15px",
                                                        }}
                                                      >
                                                        <h6 className="title">
                                                          Miscellaneous Rates
                                                        </h6>
                                                      </div>
                                                      <div className="sp-plan-desc sp-plan-desc-mb">
                                                        <table className="table">
                                                          <thead className="thead-light">
                                                            <tr>
                                                              <th scope="col">
                                                                Title
                                                              </th>
                                                              <th scope="col">
                                                                Rate Type
                                                              </th>
                                                              <th scope="col">
                                                                Rate
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {this.state.miscData.map(
                                                              (
                                                                miscrate,
                                                                idx
                                                              ) => (
                                                                <tr
                                                                  key={`mis${idx}`}
                                                                >
                                                                  <td>
                                                                    {
                                                                      miscrate.misc_title
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {miscrate.misc_type ===
                                                                    "per_minute"
                                                                      ? "Per Minute"
                                                                      : ""}
                                                                    {miscrate.misc_type ===
                                                                    "per_hour"
                                                                      ? "Per Hour"
                                                                      : ""}
                                                                    {miscrate.misc_type ===
                                                                    "one_time"
                                                                      ? "One Time"
                                                                      : ""}
                                                                  </td>
                                                                  <td>
                                                                    {miscrate.misc_rate !==
                                                                      "" &&
                                                                    miscrate.misc_rate >
                                                                      0 ? (
                                                                      <>
                                                                        $
                                                                        {miscrate.misc_rate.toFixed(
                                                                          4
                                                                        )}
                                                                      </>
                                                                    ) : (
                                                                      "$0.00"
                                                                    )}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </>
                                                  ) : null}
                                                </div>
                                                <div className="card-inner">
                                                  <div className="sp-plan-head">
                                                    <h6 className="title">
                                                      Add / Update Dates
                                                    </h6>
                                                  </div>
                                                  <div className="sp-plan-desc sp-plan-desc-mb">
                                                    <ul className="row gx-1">
                                                      <li className="col-sm-5">
                                                        <p>
                                                          <span className="text-soft">
                                                            Date Added
                                                          </span>{" "}
                                                          {services[19] !== ""
                                                            ? this.format_date(
                                                                services[19]
                                                              )
                                                            : null}
                                                        </p>
                                                      </li>
                                                      <li className="col-sm-5">
                                                        <p>
                                                          <span className="text-soft">
                                                            Date Updated
                                                          </span>{" "}
                                                          {services[20] !== ""
                                                            ? this.format_date(
                                                                services[20]
                                                              )
                                                            : null}
                                                        </p>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="card-inner">
                                                  <div className="sp-plan-head-group">
                                                    <div className="sp-plan-head">
                                                      <h6 className="title">
                                                        Short Description
                                                      </h6>
                                                      <span className="ff-italic text-soft">
                                                        {services.product_desc}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* SERVICE DETAIL */}
                        </div>
                      ) : null}

                      <img
                        className="hide_this_img image"
                        src="../../../assets/images/animation_left.gif"
                      />
                    </div>
                  </div>
                </div>
                <div className="nk-block nk-auth-footer" id="register_footer">
                  <div className="mt-3">
                    <p>
                      &copy; 2022-2023{" "}
                      <a
                        href="https://onboardsoft.com/"
                        style={{ color: "#526484" }}
                      >
                        OnBoardsoft LLC
                      </a>
                      . All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="nk-split-content nk-split-stretch bg-lighter d-flex "
                data-content="athPromo"
                data-toggle-screen="lg"
                data-toggle-overlay="true"
                style={{
                  backgroundColor: "#fff",
                  background: "#ffffff",
                  borderLeft: "1px solid #e5e9f2",
                }}
              >
                {this.state.pageError !== "" ? (
                  <div
                    className="nk-wrap nk-wrap-nosidebar"
                    style={{
                      width: "80%",
                      marginRight: "10%",
                      marginLeft: "10%",
                    }}
                  >
                    <div className="nk-content ">
                      <div className="nk-block nk-block-middle wide-md mx-auto">
                        <div className="nk-block-content nk-error-ld text-center">
                          <img
                            className="nk-error-gfx"
                            src="../../../../assets/images/error-404_1.png"
                            alt=""
                          />
                          <div className="wide-xs mx-auto">
                            <h3 className="nk-error-title">
                              Oops! Why you’re here?
                            </h3>
                            <p className="nk-error-text">
                              {this.state.pageError}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <>
                        {/* START DATATABLE */}

                        {(() => {
                          switch (step) {
                            default:
                              return (
                                <h1>
                                  User Forms not working. Enable Javascript!
                                </h1>
                              );
                            /*case 1:
                              return (
                                <Step1
                                  nextStep={this.nextStep}
                                  handleChange={this.handleChange}
                                  verifyFileId={this.verifyFileId}
                                  values={values}
                                />
                              );*/
                            case 1:
                              return (
                                <Step2
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values}
                                />
                              );
                            /*case 2:
                              return (
                                <Step3
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  values={values}
                                  handleChange={this.handleChange}
                                  //submitDomain={this.submitDomain}
                                />
                              );*/
                            case 2:
                              return (
                                <Step4
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values}
                                  //submitDomain={this.submitDomain}
                                />
                              );
                            case 3:
                              return (
                                <Step5
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  values={values}
                                  formSubmit={this.formSubmit}
                                  disabled={this.state.disabled}
                                />
                              );
                            case 4:
                              return (
                                <Step6
                                  firstStep={this.firstStep}
                                  values={values}
                                />
                              );
                            //
                            //case 6:
                            //return <Step6 firstStep={this.firstStep} />;
                          }
                        })()}
                        {/* END TABLE */}
                      </>
                    )}
                  </>
                )}
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
          </div>
        </div>
        {/*<HelmetProvider>
          <Helmet>
            <script src="../../assets/ui_latest/js/bundle.js?ver=3.0.3"></script>
            <script src="../../assets/ui_latest/js/scripts.js?ver=3.0.3"></script>
            <script src="../../assets/ui_latest/js/libs/tagify.js?ver=3.0.3"></script>
          </Helmet>
              </HelmetProvider>*/}
      </>
    );
  }
}
