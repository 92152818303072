import HelperClass from "../../config/helperClass";

import { THEME_COLOR } from "../../config/config";

import $ from "jquery";
const auth = new HelperClass();

export default function Notifications(props) {
  return (
    <div class="nk-notification-item dropdown-inner">
        <div class="nk-notification-icon">
            <em class='icon icon-circle bg-warning-dim ni ni-info'></em>
        </div>
        <div class="nk-notification-content">
            <div class="nk-notification-text">
            {props.notification.is_read === 0 ? (
              <span class="unread_notification">{props.notification.title}</span>
            ) : (
              <span>{props.notification.title}</span>
            )}
            </div>
            <div class="nk-notification-time">{props.notification.time}</div>
        </div>
    </div>
  );
}