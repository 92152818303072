import React, { Component } from "react";
import $ from "jquery";
import {
    getClientActivityLogs,
    readAllNotifications
} from "./../../../config/api_calls";
import NotiDataTable from "../../../components/Tables/NotiDataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import dateFormat, { masks } from "dateformat";
const auth = new HelperClass();

export default class Notifications extends Component {
  constructor() {
    super();
    this.state = {
        tableData: [],
        tableLoader: true,
        tableHeads: [
            { 
              name: "is_read",
              options: {
                display : false,
              }
            },
            { name: "Time Added",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const isRead = tableMeta.rowData[0];
                  const className = isRead === 1 ? '' : 'unread_notification';
                  return <span className={`${className}`}>{value}</span>;
                },
              },
            },
            { name: "Title",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const isRead = tableMeta.rowData[0];
                  const className = isRead === 1 ? '' : 'unread_notification';
                  return <span className={`${className}`}>{value}</span>;
                },
              },
            },
            { name: "Description",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const isRead = tableMeta.rowData[0];
                  const className = isRead === 1 ? '' : 'unread_notification';
                  return <span className={`${className}`}>{value}</span>;
                },
              },
            },
            
        ],
    };
  }

  async componentDidMount() {
    const clientsResponce = await getClientActivityLogs(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Client Data: ", clientsResponce.data);
    let notificationArray = [];
   if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
        const getActivityLogs_res = await getClientActivityLogs(auth.getAccount(), auth.getToken());
        let i;
        const notification = getActivityLogs_res.data.data;
        
        if (getActivityLogs_res.data.status === 200) {
          for (i = 0; i < notification.length; i++) {
              const timeAdded = new Date(notification[i].date_added);
              const formattedTimeAdded = timeAdded.toLocaleString();
              notificationArray.push({
              Title: notification[i].log_title,
              "Time Added": formattedTimeAdded,
              Description : notification[i].log_description,
              is_read : notification[i].is_read,
              });
          }
        } else if(getActivityLogs_res.data.message === 'no_notifications'){
          console.log('No notifications yet');
        }
    } else {
      console.log('error fetching notifications.')
    }
    this.setState({
      tableData: notificationArray,
      tableLoader: false,
    });
  }
  
  render() {
    
    return (
        
        <div className="nk-content " id="Clients_Block">
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">
  
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        <PageTitle name="Notifications" icon="icon ni ni-users" />
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="nk-block-head-content">
                    <Link to="/notifications">
                      <span className="badge-outline-dark">
                        <em className="icon ni ni-done"></em>Client Notifications
                      </span>
                    </Link>
                  </div>
                <div className="nk-block">
                  <div className="row g-gs">
                    <div className="col-xxl-12">
                      <div className="nk-block nk-block-lg notificationsTable">
                        {this.state.tableLoader === true ? (
                          tableLoader()
                        ) : (
                          <NotiDataTable
                            columns={this.state.tableHeads}
                            tableData={this.state.tableData}
                            title="All Notifications"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}
