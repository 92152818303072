import styles from "./styles.module.css";

import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";

import {
  saveGeneralAgreement,
  unsignedAgreements,
} from "../../config/api_calls";
import { REPLACECONTENT } from "../../config/utility";
import { React, Component } from "react";
import HelperClass from "./../../config/helperClass";

import {
  THEME_COLOR,
  THEME_TEXT_COLOR,
  VENDOR_SIGNATURE,
} from "../../config/config";

import "./agreement.css";

const auth = new HelperClass();

export default class Agreement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      successMessage: "",
      agreementData: [],
      replacable_content: "",
      final_content: "",
      agreement_id: "",
      agreement_title: "",
      signature_zero: 1,
      signature_one: 0,
      signature_two: 0,
      signature_three: 0,
      signature_four: 0,
      vendor_signature: "",
      agreement_type: "esign",
      is_checked: "",
    };
  }

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    this.props.agreementSigned();
  };

  async componentDidMount() {
    let apiData = await unsignedAgreements(auth.getAccount(), auth.getToken());
    console.log("UnSignedAgreement DATA Inner: ", apiData.data);
    if (
      apiData.data.status === 403 ||
      apiData.data.errors === "authentication missing" ||
      apiData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (apiData.data.status === 404 || apiData.data.data.length === 0) {
      console.log("First 404 Error Inner");
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
      });
      //this.props.history.push("/error");
    } else if (apiData.data.status === 200 && apiData.data.data.length > 0) {
      this.setState({});
      let objReplace = {
        resellerCompName: auth.getCompName(),
        reseller_name: apiData.data.user_data[0]["name"],
        reseller_comp_name: apiData.data.user_data[0]["comp_name"],
        reseller_email: apiData.data.user_data[0]["email"],
        reseller_address: apiData.data.user_data[0]["address"]
          ? apiData.data.user_data[0]["address"]
          : "N/A",
        reseller_city: apiData.data.user_data[0]["city"]
          ? apiData.data.user_data[0]["city"]
          : "N/A",
        reseller_state: apiData.data.user_data[0]["state"]
          ? apiData.data.user_data[0]["state"]
          : "N/A",
        reseller_zip: apiData.data.user_data[0]["zip"]
          ? apiData.data.user_data[0]["zip"]
          : "N/A",
        reseller_phone_no: apiData.data.user_data[0]["phone"],
        vendor_signor_name: apiData.data.reseller_data[0].reseller_name,
        vendor_signor_comp_name: apiData.data.reseller_data[0].reseller_comp,
        vendor_signor_title: apiData.data.reseller_data[0].reseller_title,
        vendor_signor_address: apiData.data.reseller_data[0].reseller_address,
        vendor_signor_email: "N/A",
        vendor_signor_phone_no: apiData.data.reseller_data[0].reseller_phone,
        vendor_signor_fax: "N/A",
        vendor_signor_city: apiData.data.reseller_data[0].reseller_city,
        vendor_signor_state: apiData.data.reseller_data[0].reseller_state,
        vendor_signor_zip: apiData.data.reseller_data[0].reseller_zip,
        vendor_signor_comp_title: apiData.data.reseller_data[0].reseller_comp,
      };

      /*newContent = newContent.replace(
        "VENDOR SIGNATURE DATE SHOWN HERE",
        apiData.data.agreement[0]["published_date"]
      );*/
      let final_content = apiData.data.data[0][1];
      final_content = REPLACECONTENT(final_content, objReplace, "").replace(
        /\[\[IMG_VENDOR_SIGN\]\]/g,
        this.state.vendor_signature
      );
      this.setState({
        replacable_content: objReplace,
        agreementData: apiData.data.data,
        errorMessage: "",
        final_content: final_content,
        bgColor: "white",
        defaultLoader: false,
        agreement_id: apiData.data.data[0][0],
        agreement_type: apiData.data.data[0][8],
        agreement_title: apiData.data.data[0][2],
        vendor_signature: apiData.data.reseller_data[0].reseller_signature,
      });
      //this.show_signature(0);
    } else {
      this.setState({
        errorMessage: "There is some error.",
        successMessage: "",
      });
      console.log("Last Error");
      //this.props.history.push("/error");
    }
  }

  save = async () => {
    console.log("agreement_id: " + this.state.agreement_id);

    if (
      this.state.agreement_type === "accept" &&
      this.state.is_checked !== "yes"
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must accept the agreement.",
      });
    } else if (
      this.state.agreement_type === "esign" &&
      this.signaturePad.isEmpty()
    ) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must sign the mandatory agreements.",
      });
    } else {
      $("#loading_btn").show();
      $("#save_btn").hide();

      this.setState({
        errorMessage: "",
      });
      var data = "";
      if (this.state.agreement_type === "esign") {
        data = this.signaturePad.getTrimmedCanvas().toDataURL("image/svg+xml");
      }

      const apiData = await saveGeneralAgreement(
        auth.getAccount(),
        auth.getToken(),
        data,
        this.state.final_content.replace(
          "VENDOR SIGNATURE DATE SHOWN HERE",
          this.props.published_date
        ),
        this.state.agreement_id,
        this.state.vendor_signature,
        this.state.agreement_type
      );
      console.log("MSA: ", apiData.data);
      if (
        apiData.data.status === 403 ||
        apiData.data.errors === "authentication missing" ||
        apiData.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (apiData.data.status === 404) {
        window.$("#modalAlertError").modal("show");
        return this.setState({
          errorMessage: "We are sorry, something went wrong try later.",
        });
        // this.props.mainHistory.history.push("/error");
        // console.log("errors: ", apiData.data);
      } else if (
        apiData.data.status === 200 &&
        apiData.data.data === "success"
      ) {
        $("#loading_btn").hide();
        $("#save_btn").show();
        /*this.setState({
          successMessage: "Congratulations!",
        });
        window.$("#modalAlert").modal("show");*/
        // setTimeout(() => {
        window.location.replace("/agreements");
        //window.location.reload(true);
        //}, 1000);
      }

      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  set_data = (agreement_content) => {
    this.setState({
      final_content: agreement_content,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("handleChange: ", name + " " + value);
  };

  preview = () => {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  class_manage_link = (ids) => {
    if (ids === 0) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  };

  class_manage_content = (ids) => {
    if (ids === 0) {
      return "tab-pane active";
    } else {
      return "tab-pane";
    }
  };

  class_required = (ids) => {
    if (ids === 1) {
      return (
        <sup style={{ fontSize: "100%" }}>
          <small className="" style={{ color: "#e85347", fontSize: "100%" }}>
            *
          </small>
        </sup>
      );
    } else {
      return "";
    }
  };

  set_tabs = (ids) => {
    if (ids !== "") {
      return "#agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  set_tabs_content = (ids) => {
    if (ids !== "") {
      return "agreement" + ids;
    } else {
      return "tab-pane";
    }
  };

  show_agreement_content = async (
    ids,
    agreement_content,
    agreement_type,
    agreement_title
  ) => {
    console.log("replacable_content: ", this.state.replacable_content);
    let final_content = agreement_content;
    if (final_content !== "") {
      final_content = REPLACECONTENT(
        agreement_content,
        this.state.replacable_content,
        ""
      ).replace(/\[\[IMG_VENDOR_SIGN\]\]/g, this.state.vendor_signature);
    }
    this.setState({
      final_content: final_content,
      agreement_id: ids,
      agreement_type: agreement_type,
      agreement_title: agreement_title,
    });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    {this.state.agreementData.length > 0 ? (
                      <h3 className="nk-block-title page-title">Agreements</h3>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div
                        className="card card-bordered"
                        style={{
                          backgroundColor: this.props.backgroundColor,
                          border: "none",
                        }}
                      >
                        {this.state.agreementData.length > 0 ? (
                          <div className="card-inner card-inner-xl">
                            <ul className="nav nav-tabs">
                              {this.state.agreementData.map((agreements, i) => (
                                <li className="nav-item" key={`agr${i}`}>
                                  <a
                                    className={this.class_manage_link(i)}
                                    data-toggle="tab"
                                    href={this.set_tabs(agreements[6])}
                                    onClick={() =>
                                      this.show_agreement_content(
                                        agreements[0],
                                        agreements[1],
                                        agreements[8],
                                        agreements[2]
                                      )
                                    }
                                  >
                                    <em className="icon ni ni-list-round"></em>
                                    <span>
                                      {agreements[2]}{" "}
                                      {this.class_required(agreements[3])}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <div className="tab-content">
                              {this.state.agreementData.map(
                                (agreementscontent, i) => (
                                  <div
                                    className={this.class_manage_content(i)}
                                    id={this.set_tabs_content(
                                      agreementscontent[6]
                                    )}
                                    key={`agrcont${i}`}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: REPLACECONTENT(
                                          agreementscontent[1],
                                          this.state.replacable_content,
                                          ""
                                        ).replace(
                                          /\[\[IMG_VENDOR_SIGN\]\]/g,
                                          this.state.vendor_signature
                                        ),
                                      }}
                                    />
                                  </div>
                                )
                              )}
                              {this.state.agreement_type === "esign" ? (
                                <div id="signature_div">
                                  <Row>
                                    <Col sm={12}>
                                      <div>
                                        <strong>Sign Here:</strong>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: "1%" }}>
                                    <Col sm={12}>
                                      <SignatureCanvas
                                        clearOnResize={true}
                                        canvasProps={{
                                          height: "400px",
                                          className: styles.sigPad,
                                        }}
                                        velocityFilterWeight={0.7}
                                        throttle={50}
                                        ref={(ref) => {
                                          this.signaturePad = ref;
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: "1%" }}>
                                    <Col sm={12}>
                                      <div style={{ float: "right" }}>
                                        <button
                                          className="btn btn-lg btn-warning"
                                          style={{
                                            margin: "13px 12px 12px 10px",
                                          }}
                                          onClick={() => this.clear()}
                                        >
                                          Clear
                                        </button>
                                        <button
                                          className="btn btn-lg btn-success"
                                          style={{
                                            margin: "13px 12px 12px 10px",
                                          }}
                                          onClick={() => this.preview()}
                                        >
                                          Preview Signature
                                        </button>
                                        <button
                                          className="btn btn-lg"
                                          type="button"
                                          id="loading_btn"
                                          style={{
                                            display: "none",
                                            backgroundColor: THEME_COLOR,
                                            color: THEME_TEXT_COLOR,
                                          }}
                                          disabled
                                        >
                                          <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span> Wait... </span>
                                        </button>
                                        <button
                                          className="btn btn-lg"
                                          type="button"
                                          id="loading_btn"
                                          style={{
                                            display: "none",
                                            backgroundColor: THEME_COLOR,
                                            color: THEME_TEXT_COLOR,
                                          }}
                                          disabled
                                        >
                                          <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>
                                          <span> Wait... </span>
                                        </button>
                                        <button
                                          className="btn btn-lg"
                                          style={{
                                            backgroundColor: THEME_COLOR,
                                            color: THEME_TEXT_COLOR,
                                            margin: "13px 12px 12px 10px",
                                          }}
                                          onClick={() => this.save()}
                                          id="save_btn"
                                        >
                                          Save Agreement
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ) : null}
                              {this.state.agreement_type === "accept" ? (
                                <div
                                  id="signature_div"
                                  style={{ width: "50%" }}
                                >
                                  <div className="nk-block">
                                    <div className="row g-gs">
                                      <div className="col-md-12">
                                        <div className="card card-bordered card-full">
                                          <div className="nk-wg1">
                                            <div className="nk-wg1-block">
                                              <div className="nk-wg1-text">
                                                <div className="form-group">
                                                  <div className="custom-control custom-control-xs custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      name="is_checked"
                                                      className="custom-control-input"
                                                      id="checkbox"
                                                      defaultChecked={
                                                        this.state.is_checked
                                                      }
                                                      value="yes"
                                                      onChange={
                                                        this.handleChange
                                                      }
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor="checkbox"
                                                    >
                                                      I accept the{" "}
                                                      {
                                                        this.state
                                                          .agreement_title
                                                      }
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="nk-wg1-action"
                                              style={{ padding: "1.25rem" }}
                                            >
                                              <div className="form-group ">
                                                <button
                                                  className="btn btn-lg"
                                                  type="button"
                                                  id="loading_btn"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                  }}
                                                  disabled
                                                >
                                                  <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  <span> Wait... </span>
                                                </button>
                                                <button
                                                  className="btn btn-lg"
                                                  type="button"
                                                  id="loading_btn"
                                                  style={{
                                                    display: "none",
                                                    backgroundColor:
                                                      THEME_COLOR,
                                                    color: THEME_TEXT_COLOR,
                                                  }}
                                                  disabled
                                                >
                                                  <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                  <span> Wait... </span>
                                                </button>
                                                <button
                                                  className="btn btn-lg btn-primary"
                                                  onClick={() => this.save()}
                                                  id="save_btn"
                                                >
                                                  Save Agreement
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {/* ACTION BUTTONS DIV  START*/}

                              {/* ACTIONS BUTTONS DIV END */}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* SUCCESS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      onClick={() => {
                        this.modalHide();
                      }}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">
                          {this.state.successMessage}
                        </h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            You’ve successfully signed{" "}
                            <strong>Agreement</strong>
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => {
                              this.modalHide();
                            }}
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SUCCESS MODAL End */}
              {/* ERROR MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalAlertError">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4 className="nk-modal-title">Unable to Process!</h4>
                        <div className="nk-modal-text">
                          <p className="lead">{this.state.errorMessage}</p>
                        </div>
                        <div className="nk-modal-action mt-5">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-light"
                            data-dismiss="modal"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ERROR MODAL END */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
