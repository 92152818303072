import React, { Component } from "react";
import $ from "jquery";
import {
    getActivityLogs,
    readAllNotifications,
    getClientActivityLogs,
    getResellerActivityLogs
} from "./../../../config/api_calls";
import NotiDataTable from "../../../components/Tables/NotiDataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import dateFormat, { masks } from "dateformat";
const auth = new HelperClass();

export default class Notifications extends Component {
  constructor() {
    super();
    this.state = {
        tableData: [],
        tableDataClients: [],
        tableDataReseller: [],
        tableLoader: true,
        tableHeads: [
            { 
              name: "is_read",
              options: {
                display : false,
              }
            },
            { name: "Time Added",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const isRead = tableMeta.rowData[0];
                  const className = isRead === 1 ? '' : 'unread_notification';
                  return <span className={`${className}`}>{value}</span>;
                },
              },
            },
            { name: "Title",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const isRead = tableMeta.rowData[0];
                  const className = isRead === 1 ? '' : 'unread_notification';
                  return <span className={`${className}`}>{value}</span>;
                },
              },
            },
            { name: "Description",
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  const isRead = tableMeta.rowData[0];
                  const className = isRead === 1 ? '' : 'unread_notification';
                  return <span className={`${className}`}>{value}</span>;
                },
              },
            },
            
        ],
    };
  }

  async componentDidMount() {
    this.agentActivityLogs();
  }

  clientActivityLogs = async () => {
    this.setState({
      tableLoader: true,
    });
    const getActivityLogs_res = await getClientActivityLogs(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Clients noitification Data: ", getActivityLogs_res.data);
   if (
      getActivityLogs_res.data.status === 200 &&
      getActivityLogs_res.data.message === "success"
    ) {
        let i;
        const notification = getActivityLogs_res.data.data;
        console.log('notifications:',notification)
        let notificationArray = [];
        if (getActivityLogs_res.data.status === 200) {
        for (i = 0; i < notification.length; i++) {
            const timeAdded = new Date(notification[i].date_added);
            const formattedTimeAdded = timeAdded.toLocaleString();
            notificationArray.push({
            Title: notification[i].log_title,
            "Time Added": formattedTimeAdded,
            Description : notification[i].log_description,
            is_read : 1,
            });
        }
        } else if(getActivityLogs_res.data.message === 'no_notifications'){
        console.log('No notifications yet');
        }
        this.setState({
          tableDataClients: notificationArray,
          tableLoader: false,
        });
    } else {
        this.setState({
          tableLoader: false,
        });
    }
  };

  agentActivityLogs = async () => {
    this.setState({
      tableLoader: true,
    });
    const getActivityLogs_res = await getActivityLogs(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Noitification Data: ", getActivityLogs_res.data);
   if (
      getActivityLogs_res.data.status === 200 &&
      getActivityLogs_res.data.message === "success"
    ) {
        let i;
        const notification = getActivityLogs_res.data.data;
        let notificationArray = [];
        if (getActivityLogs_res.data.status === 200) {
        for (i = 0; i < notification.length; i++) {
            const timeAdded = new Date(notification[i].date_added);
            const formattedTimeAdded = timeAdded.toLocaleString();
            notificationArray.push({
            Title: notification[i].log_title,
            "Time Added": formattedTimeAdded,
            Description : notification[i].log_description,
            is_read : notification[i].is_read,
            });
        }
        } else if(getActivityLogs_res.data.message === 'no_notifications'){
        console.log('No notifications yet');
        }
        this.setState({
        tableData: notificationArray,
        tableLoader: false,
        });
    } else {
      window.location.replace("/error");
    }
  };
  
  resellerActivityLogs = async () => {
    this.setState({
      tableLoader: true,
    });
    const getActivityLogs_res = await getResellerActivityLogs(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Clients noitification Data: ", getActivityLogs_res.data);
   if (
      getActivityLogs_res.data.status === 200 &&
      getActivityLogs_res.data.message === "success"
    ) {
        let i;
        const notification = getActivityLogs_res.data.data;
        console.log('notifications:',notification)
        let notificationArray = [];
        if (getActivityLogs_res.data.status === 200) {
        for (i = 0; i < notification.length; i++) {
            const timeAdded = new Date(notification[i].date_added);
            const formattedTimeAdded = timeAdded.toLocaleString();
            notificationArray.push({
            Title: notification[i].log_title,
            "Time Added": formattedTimeAdded,
            Description : notification[i].log_description,
            is_read : 1,
            });
        }
        } else if(getActivityLogs_res.data.message === 'no_notifications'){
        console.log('No notifications yet');
        }
        this.setState({
          tableDataReseller: notificationArray,
          tableLoader: false,
        });
    } else {
        this.setState({
          tableLoader: false,
        });
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        <strong>Notifications</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-gs notifications_table" style={{ clear: "both", marginTop: "0px !important" }}>
                <div className="col-lg-12">
                  <div className="card card-bordered" style={{ clear: "both" }}>
                    <div className="card-inner">
                      <ul class="nav nav-tabs">
                        <li class="nav-item ">
                          <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#tabItem6"
                            onClick={(e) => { this.agentActivityLogs(); }}
                          >
                            <em class="icon ni ni-users"></em>
                            <span>Agent</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabItem7"
                            onClick={(e) => { this.resellerActivityLogs(); }}
                          >
                            <em class="icon ni ni-user-list"></em>
                            <span>Admin</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#tabItem8"
                            onClick={(e) => { this.clientActivityLogs(); }}
                          >
                            <em class="icon ni ni-user-list"></em>
                            <span>Clients</span>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane active" id="tabItem6">
                          <div className="col-lg-12 notificationsTable">
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <NotiDataTable
                                columns={this.state.tableHeads}
                                tableData={this.state.tableData}
                                title="Agent Notifications"
                              />
                            )}
                          </div>
                        </div>
                        <div class="tab-pane" id="tabItem7">
                        <div className="col-lg-12 notificationsTable">
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <NotiDataTable
                                columns={this.state.tableHeads}
                                tableData={this.state.tableDataReseller}
                                title="Admin Notifications"
                              />
                            )}
                        </div>
                        </div>
                        <div class="tab-pane" id="tabItem8">
                        <div className="col-lg-12 notificationsTable">
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <NotiDataTable
                                columns={this.state.tableHeads}
                                tableData={this.state.tableDataClients}
                                title="Clients Notifications"
                              />
                            )}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
