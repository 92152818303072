import HelperClass from "./../../config/helperClass";

import { THEME_COLOR } from "./../../config/config";

import $ from "jquery";
import { Button } from "react-bootstrap";
const auth = new HelperClass();

export default function Header(props) {
  // function profileView() {
  //   window.$("#modalForm").modal("show");
  // }

  function classCheck() {
    var menu_items = $(".nk-sidebar");
    if (menu_items.hasClass("nk-sidebar-active")) {
    } else {
      $(".nk-sidebar").addClass("nk-sidebar-active");
    }
  }
  console.log("Header props: ", props);
  function accountActivity() {
    props.mainProps.history.push(
      "/" + auth.getResellerRegToken() + "/client/AccountActivity"
    );
  }

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu" onClick={() => classCheck()}></em>
            </a>
          </div>

          <div className="nk-header-brand d-xl-none">
            <a href="/" className="logo-link">
              <img
                className="logo-light logo-img"
                src="https://www.telecomcloud.us/img/logo.png"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="https://www.telecomcloud.us/img/logo.png"
                alt="logo-dark"
              />
            </a>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {props.isNdaSigned === true ? (
                <li>
                  {/*<a
                    className="btn"
                    style={{ backgroundColor: THEME_COLOR, color: "white" }}
                  >
                    <em className="icon ni ni-share-fill"></em>
                    <span>ShareLink</span>{" "}
                  </a>*/}
                </li>
              ) : null}

              <li className="dropdown user-dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt"></em>
                    </div>
                    <div className="user-info d-none d-md-block">
                      <div className="user-status">{auth.getNameClient()}</div>
                      <div className="user-name dropdown-indicator">
                        {auth.getCompNameClient()}
                      </div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span>{auth.getCompNameClient().substring(0, 2)}</span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">
                          {auth.getCompNameClient()}
                        </span>
                        <span className="sub-text">
                          {auth.getEmailClient()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <a
                          onClick={props.modalOpen}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <em className="icon ni ni-user-alt"></em>
                          <span>View Profile</span>
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => accountActivity()}
                        >
                          <em class="icon ni ni-list"></em>
                          <span>Account Activity</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li
                        onClick={() => auth.clientLogout()}
                        style={{ cursor: "pointer" }}
                      >
                        <a>
                          <em className="icon ni ni-signout"></em>
                          <span>Sign out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
