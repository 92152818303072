import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import PDFViewer from "pdf-viewer-reactjs";
import { addSettings } from "../../../actions";
import { getAgreementData } from "./../../../config/api_calls";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";

const auth = new HelperClass();

class Pdfdownload extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      page_id: "",
      pdflink: "",
    };
  }

  async componentDidMount() {
    let page_id = atob(this.props.props.match.params.id);

    console.log("this.props = ", page_id);
    const reportsData = await getAgreementData(
      auth.getAccount(),
      auth.getToken(),
      page_id
    );
    console.log("reportsData.data.data: ", reportsData.data.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "Agreement does not exist.",
        disabled: true,
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        page_id: page_id,
        pdflink: reportsData.data.data,
      });
      var pdf_path_array = page_id.split("###");

      var pdf_type = pdf_path_array[0];
      var actual_path = "";

      actual_path = "agreement.pdf";

      fetch(reportsData.data.data)
        .then((x) => x.blob())
        .then((b) => {
          console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = actual_path;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      setTimeout(() => {
        this.props.props.history.push("/agreements");
      }, 3000);

      //window.location.replace("/agreements");
    } else {
      this.setState({
        errorMessage: "Agreement does not exist.",
        disabled: true,
      });
      //window.location.replace("/error");
    }
  }

  render() {
    return (
      <div className="nk-content " id="Pdfdownload_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          {this.state.errorMessage !== "" ? (
            <div className="example-alert">
              <div className="alert alert-pro alert-danger">
                <div className="alert-text">
                  <h4>Error</h4>
                  <p>{this.state.errorMessage}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pdfdownload);
