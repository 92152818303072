import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listCreditApplications,
  singleCreditApplication,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import BusinessInformation from "../../../components/Client/CreditApplication/BusinessInformation";
import FinancialInformation from "../../../components/Client/CreditApplication/FinancialInformation";
import TradeReferences from "../../../components/Client/CreditApplication/TradeReferences";
import SignatoryDetails from "../../../components/Client/CreditApplication/SignatoryDetails";
import SsoSuccess from "../../../components/Client/CreditApplication/Success";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class CreditApp extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      columnServices: [
        { name: "ID" },
        {
          name: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "View",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewPdf(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Complete",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setComplete(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Approved Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "Rejected Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              {tableMeta.rowData[4] === "incomplete" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "addCreditApp/" + value
                                      )
                                    }
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Edit Credit Application"
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span>Edit Credit App</span>
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Reason / Step Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Show Details</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listCreditApplications(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Available Credit Applications: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "pending") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Pending
          </span>
        </div>
      );
    } else if (domain_str === "approved") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done "></em> Approved
          </span>
        </div>
      );
    } else if (domain_str === "rejected") {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Rejected
          </span>
        </div>
      );
    }
  };

  setComplete = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "incomplete") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In Progress
          </span>
        </div>
      );
    } else if (domain_str === "complete") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done "></em> Submitted
          </span>
        </div>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download PDF"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em class="icon ni ni-file-pdf"></em> View PDF
            </span>
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> View PDF
          </span>
        </div>
      );
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_content === "link") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Product Info
          </span>
        </a>
      );
    } else if (marketing_content === "pdf") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em> View PDF
          </span>
        </a>
      );
    } else {
      return "";
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  showDetails = async (value, tableMeta) => {
    const servicesResponce = await singleCreditApplication(
      auth.getClientAccount(),
      auth.getClientToken(),
      value
    );
    console.log("showDetails: ", servicesResponce.data);
    window.$("#modalFormDetails").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableDataSingle: servicesResponce.data.data,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  };

  downloadPdf = async (d_f_name) => {
    var url = APP_LIVE_URL + "files_data/credit_application/" + d_f_name;
    this.setState({
      errorMessage: ""
    });
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch((error) => {
        // Handle the error, such as showing an error message to the user
        this.setState({
          errorMessage: "There is some error while downloading the PDF."
        });
        
        console.error('Fetch error:', error);
      });
      
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  generateRandomNumber = (link) => {
    const min = 1000000000; // Minimum 10-digit number (10^9)
    const max = 9999999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    this.props.props.history.push(link + "/" + randomNumber);
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-unlock"></em> Other Details
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}

                      <div className=" g-4">
                        {this.state.tableLoaderListing === true ? (
                          tableLoaderListing()
                        ) : (
                          <>
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-os">
                                      <span class="overline-title">Title</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Value</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tableDataSingle &&
                                  this.state.tableDataSingle.length > 0 ? (
                                    <>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #1:</strong> Business
                                          Information
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "business_information_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #2:</strong> Financial
                                          Information
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "financial_information_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #3:</strong> Trade
                                          References
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "trade_ref_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #4:</strong> Signatory
                                          Details
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "signatory_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      {this.state.tableDataSingle[0][
                                        "reason"
                                      ] &&
                                      this.state.tableDataSingle[0][
                                        "reason"
                                      ] !== "" ? (
                                        <>
                                          <tr>
                                            <td class="tb-col-os" colSpan={2}>
                                              <strong>
                                                Reason of rejection
                                              </strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="tb-col-os" colSpan={2}>
                                              {
                                                this.state.tableDataSingle[0][
                                                  "reason"
                                                ]
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Credit Application"
                        icon="icon ni ni-coin"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            {this.props.defaultSettings !== "missing" ? (
                              <a
                                onClick={() =>
                                  this.generateRandomNumber("addCreditApp")
                                }
                                style={{
                                  color: "#fff",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Credit Application</span>
                              </a>
                            ) : null}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                        {this.state.errorMessage !== "" ? (
                                  <div className="example-alert mb-5">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreditApp);
