import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listServices,
  serviceRatesUpdate,
  serviceDefaultUpdate,
  getAgentService,
  listDefaultServices,
  listBundleServices,
  getBundleServiceDetails,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class Services extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      tableLoaderBundle: true,
      nrc_rate_type: "Flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: "",
      new_mrc: "",
      rate_type: "",
      rate: "",
      defaultRate: "",
      defaultSmsIn: "",
      defaultSmsOut: "",
      agent_mrc_comission: 0,
      agent_nrc_comission: 0.0,
      agent_rate_comission: 0,
      sms_in_comission: 0,
      sms_out_comission: 0,
      update_nrc: "",
      update_mrc: "",
      update_rate: "",
      product_id: "",
      product_desc: "",
      mrc_rate_type: "Flat",
      service_title: "",
      service_type_edit: "",
      default_mrc: "",
      default_nrc: "",
      default_rate: "",
      default_sms_in: "",
      default_sms_out: "",
      sms_in: "",
      sms_out: "",
      sms_in_percentage: "",
      sms_out_percentage: "",
      mrc_percentage: "",
      rate_percentage: "",
      select_service: "",
      bundleId: "",
      columnBundleServices: [],
      columnServices: [
        { name: "Id" },
        { name: "Title" },

        {
          name: "Date Added",
          options: {
            customBodyRender: (value) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Date Updated",
          options: {
            customBodyRender: (value) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "Marketing",
          options: {
            customBodyRender: (value,tableMeta) => {
              return <div>{this.marketingLink(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showDetails(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Service Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Service Details</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  marketingLink = (value, tableMeta) => {
    let is_default = tableMeta.rowData[4];
    let marketing = is_default.split("___");
    if (marketing[0] === "link") {
      return (
        <Tooltip title="Open Marketing Link" placement="top">
          <a
            style={{ cursor: "pointer" }}
            href={marketing[1]}
            target="_blank"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View Link
            </span>
          </a>
        </Tooltip>
      );
    } else if (marketing[0] === "pdf") {
      return (
        <Tooltip title="Download PDF" placement="top">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.downloadMarketingPdf(marketing[1])}
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View PDF
            </span>
          </a>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
           ---
        </span>
      );
    }
  };

  marketingLinkPackage = (value) => {
    let is_default = value;
    let marketing = is_default.split("___");
    if (marketing[0] === "link") {
      return (
        <Tooltip title="Open Marketing Link" placement="top">
          <a
            style={{ cursor: "pointer" }}
            href={marketing[1]}
            target="_blank"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View Link
            </span>
          </a>
        </Tooltip>
      );
    } else if (marketing[0] === "pdf") {
      return (
        <Tooltip title="Download PDF" placement="top">
          <a
            style={{ cursor: "pointer" }}
            onClick={() => this.downloadMarketingPdf(marketing[1])}
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            <span className="badge badge-outline-primary">
              <em className="icon ni ni-signin"></em> View PDF
            </span>
          </a>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
           ---
        </span>
      );
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    console.log("is_default: ",is_default);
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else if (is_default === "Yes") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  async componentDidMount() {
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  get_default_services = async () => {
    const servicesResponce = await listDefaultServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Service Data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        defaultServices: servicesResponce.data.data,
        completeData: servicesResponce.data.all_data,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleStatesDynamic = async (
    serviceId,
    update_nrc,
    update_mrc,
    update_rate,
    service_title,
    service_type_edit,
    sms_in,
    sms_out
  ) => {
    this.setState({
      serviceId: serviceId,
      update_nrc: update_nrc,
      update_mrc: update_mrc,
      update_rate: update_rate,
      service_title: service_title,
      service_type_edit: service_type_edit,
      sms_in: sms_in,
      sms_out: sms_out,
    });
  };

  showDetails = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getAgentService(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("getAgentService: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetail").modal("show");
  };

  getBundleServices = async () => {
    const bundleResponce = await listBundleServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("bundleResponce.data.data: ", bundleResponce.data.data);

    if (
      bundleResponce.data.status === 403 ||
      bundleResponce.data.errors === "authentication missing" ||
      bundleResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (bundleResponce.data.status === 404) {
      this.setState({
        tableDataBundle: [],
        tableLoaderBundle: false,
      });
    } else if (
      bundleResponce.data.status === 200 &&
      bundleResponce.data.message === "success"
    ) {
      this.setState({
        tableDataBundle: bundleResponce.data.data,
        tableLoaderBundle: false,
        columnBundleServices: [
          {
            name: "bundle_title",
            label: "Title",
            options: {
              filter: true,
            },
          },
          {
            name: "per_month_rate",
            label: "Per Month Rate (Charge Monthly)",
            options: {
              filter: true,
            },
          },
          {
            name: "per_year_rate",
            label: "Per Month Rate (Charge Yearly)",
            options: {
              filter: true,
            },
          },
          {
            name: "date_added",
            label: "Date Added",
            options: {
              customBodyRender: (value) => {
                return <div>{this.format_date(value)}</div>;
              },
            },
          },
          {
            name: "marketing",
            label: "Marketing",
            options: {
              customBodyRender: (value) => {
                return <div>{this.marketingLinkPackage(value)}</div>;
              },
            },
          },
          {
            name: "id",
            label: "Actions",
            options: {
              customBodyRender: (value) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip
                                    title="Bundle Details"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.showBundleDetails(value)
                                      }
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-list"></em>
                                      <span>Bundle Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                                <li>
                                  <Tooltip
                                    title="Assign agreement to the bundle"
                                    placement="top"
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      disabled={
                                        this.props.is_msa_signed === "No" ||
                                        this.props.defaultSettings === "missing"
                                          ? true
                                          : false
                                      }
                                    >
                                      <em className="icon ni ni-link-group"></em>
                                      <span>Bundle Agreement</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      this.setState({
        tableDataBundle: [],
        tableLoaderBundle: false,
      });
    }
  };

  showBundleDetails = async (value) => {
    let service_id = value;
    console.log("Bundle Services: ", service_id);

    const servicesResponce = await getBundleServiceDetails(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("showBundleDetails: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the bundle data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        bundleServiceDetails: servicesResponce.data.bundleServices,
        bundleDetails: servicesResponce.data.bundleData,
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the bundle data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetailBundle").modal("show");
  };

  stopAndShowService = async (serviceId, bundleId) => {
    window.$("#modalFormDetailBundle").modal("hide");
    this.showDetailsAgain(serviceId, bundleId);
  };

  stopAndShowBundle = async (bundleId) => {
    window.$("#modalFormDetail").modal("hide");
    this.showBundleDetails(bundleId);
  };

  showDetailsAgain = async (value, bundleId) => {
    let service_id = value;
    const servicesResponce = await getAgentService(
      auth.getAccount(),
      auth.getToken(),
      service_id
    );
    console.log("getService: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        errorMessage: "",
        bundleId: bundleId,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting the service data.",
        successMessage: "",
      });
      //window.location.replace("/error");
    }
    window.$("#modalFormDetail").modal("show");
  };

  setDefaultService = async (value, tableMeta) => {
    let id = tableMeta.rowData[0];
    let service_id = tableMeta.rowData[10];
    let service_type = tableMeta.rowData[5];
    let nrc = tableMeta.rowData[3];
    let mrc = tableMeta.rowData[4];
    let rate = tableMeta.rowData[6];

    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      waitMessage: "Please wait while we are making this service to default.",
      disabled: true,
      successMessage: "",
    });
    window.$("#modalFormDefault").modal("show");

    const saveResponce = await serviceDefaultUpdate(
      auth.getAccount(),
      auth.getToken(),
      id,
      service_id,
      service_type,
      nrc,
      mrc,
      rate
    );
    console.log("saveResponce.data: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        waitMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Service has been changed to default successfully.",
        disabled: false,
        waitMessage: "",
      });

      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDefault").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
        waitMessage: "",
      });
      //window.location.replace("/error");
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSave = async () => {
    let {
      update_nrc,
      update_mrc,
      service_title,
      service_type_edit,
      update_rate,
      sms_in,
      sms_out,
      product_desc,
      default_nrc,
      default_mrc,
      default_rate,
      default_sms_in,
      default_sms_out,
      agent_mrc_comission,
      agent_nrc_comission,
      agent_rate_comission,
      sms_in_comission,
      sms_out_comission,
    } = this.state;

    var is_validated = false;
    if (service_title === "") {
      is_validated = true;
    } else if (service_type_edit === "sms") {
      if (sms_in === "") {
        is_validated = true;
      } else {
        if (sms_in < this.state.default_sms_in) {
          is_validated = true;
          this.setState({
            sms_in_error: true,
          });
        }
      }
      if (sms_out === "") {
        is_validated = true;
      } else {
        if (sms_out < this.state.default_sms_out) {
          is_validated = true;
          this.setState({
            sms_out_error: true,
          });
        }
      }
    } else if (service_type_edit === "term") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        console.log("rate > defaultRate: ", update_rate + " " + default_rate);
        if (update_rate < default_rate) {
          is_validated = false;
          this.setState({
            rate_error: true,
          });
        }
      }
    } else if (service_type_edit === "ivr") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
      }
    } else if (service_type_edit === "live_agent") {
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
      }
    } else if (service_type_edit === "did" || service_type_edit === "tfn") {
      if (update_nrc === "") {
        is_validated = true;
      } else {
        if (update_nrc < this.state.default_nrc) {
          is_validated = true;
          this.setState({ nrc_error: true });
        }
      }
      if (agent_nrc_comission === "" || agent_nrc_comission === 0) {
        is_validated = true;
      } else {
        let nrc_diff = update_nrc - this.state.default_nrc;
        if (agent_nrc_comission > nrc_diff) {
          is_validated = true;
          this.setState({ nrc_comission_error: true });
        }
      }
      if (update_mrc === "") {
        is_validated = true;
      } else {
        if (update_mrc < this.state.default_mrc) {
          is_validated = true;
          this.setState({ mrc_error: true });
        }
      }
      if (update_rate === "") {
        is_validated = true;
      } else {
        if (update_rate < this.state.default_rate) {
          is_validated = true;
          this.setState({
            rate_error: true,
          });
        }
      }
    }
    /*else if (service_type_edit === "tfn") {
      if (update_nrc === "") {
        is_validated = true;
      }
      if (update_mrc === "") {
        is_validated = true;
      }
      if (update_rate === "") {
        is_validated = true;
      }
    }*/

    if (is_validated) {
      return this.setState({
        errorMessage:
          "Please enter the required fields or check the highlighted text fields.",
      });
    } else {
      this.setState({ errorMessage: "" });

      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await serviceRatesUpdate(
        auth.getAccount(),
        auth.getToken(),
        update_nrc,
        update_mrc,
        update_rate,
        this.state.serviceId,
        service_title,
        service_type_edit,
        sms_in,
        sms_out,
        this.state.agent_mrc_comission,
        this.state.agent_nrc_comission,
        this.state.agent_rate_comission,
        this.state.default_mrc,
        this.state.default_rate,
        this.state.default_nrc,
        this.state.sms_in_comission,
        this.state.sms_out_comission,
        this.state.product_desc
      );

      console.log("status: ", saveResponce.data);
      //console.log("message: ", saveResponce.data.message);
      //console.log("note: ", saveResponce.data.note);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Rates Saved Successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalForm").modal("hide");
        }, 3000);
        //this.componentDidMount();
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };
  modalHide = () => {
    window.$("#modalFormDetail").modal("hide");
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleChangeDefault = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("handleChangeDefault: ", name + " " + value);
    let service_data = this.state.defaultServices;
    console.log("serviceData: ", service_data);
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][0] == value) {
        this.setState({
          product_id: service_data[i][0],
          defaultNrc: Number.parseFloat(service_data[i][3]).toFixed(4),
          defaultMrc: Number.parseFloat(service_data[i][4]).toFixed(4),
          rate_type: service_data[i][5],
          defaultRate: Number.parseFloat(service_data[i][5]).toFixed(4),
          defaultSmsIn: service_data[i][7],
          defaultSmsOut: service_data[i][8],
          service_type_edit: service_data[i][9],
        });
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Services" icon="icon ni ni-card-view" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            {/*{this.props.defaultSettings !== "missing" ? (
                              <a
                                href="/services/Addservice"
                                style={{
                                  color: "#fff",
                                }}
                                className="btn btn-primary"
                              >
                                <em className="icon ni ni-plus-round"></em>
                                <span>Add New Service</span>
                              </a>
                              ) : null}*/}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-user"></em>
                            <span>Services</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItem6"
                            onClick={() => this.getBundleServices()}
                          >
                            <em className="icon ni ni-lock-alt"></em>
                            <span>Packages</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tabItem5">
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTable
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title="All Services"
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                        <div className="tab-pane" id="tabItem6">
                          {this.state.tableLoaderBundle === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.tableDataBundle &&
                              this.state.tableDataBundle.length > 0 ? (
                                <DataTableExtended
                                  columns={this.state.columnBundleServices}
                                  tableData={this.state.tableDataBundle}
                                  title="All Packages"
                                />
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/*  DETAILS MODAL*/}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetail"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em>{" "}
                              Service Details
                              {this.state.bundleId !== "" ? (
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.stopAndShowBundle(this.state.bundleId)
                                  }
                                >
                                  {"   "}
                                  <span className="badge badge-outline-primary">
                                    <em className="icon ni ni-step-back"></em>{" "}
                                    Go Back
                                  </span>
                                </a>
                              ) : null}
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.serviceData ? (
                            <>
                              <div>
                                {this.state.serviceData.map((services, idx) => (
                                  <div
                                    className="card card-bordered"
                                    key={`servi${idx}`}
                                  >
                                    <div className="card-inner-group">
                                      {services[6] === "did" ||
                                      services[6] === "tfn" ||
                                      services[6] === "teams" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "Voice Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "CNAM"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    NRC
                                                    <Tooltip
                                                      title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Spiff
                                                    <Tooltip
                                                      title="Sales Performance Incentive Fund is an Incentive amount earned against a sale / subscription."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[4]} / $
                                                  {services[13]}
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    MRC{" "}
                                                    <Tooltip
                                                      title="Monthly Recurring Charge - what you pay each month for the service."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[5]} / $
                                                  {(
                                                    (services[5] -
                                                      services[15]) *
                                                    (services[12] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[12]}%)
                                                </p>
                                              </li>
                                              {services[23] !== "ratedeck" &&
                                              services[28] !== "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Minute{" "}
                                                      <Tooltip
                                                        title="Rate assigned per minute."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]} / $
                                                    {(
                                                      (services[7] -
                                                        services[16]) *
                                                      (services[14] / 100)
                                                    ).toFixed(2)}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                              {services[28] === "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Session{" "}
                                                      <Tooltip
                                                        title="Rate assigned per session."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]} / $
                                                    {(
                                                      (services[7] -
                                                        services[32]) *
                                                      (services[14] / 100)
                                                    ).toFixed(2)}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "term" ||
                                      services[6] === "ivr" ||
                                      services[6] === "live_agent" ||
                                      services[6] === "tfn_term" ||
                                      services[6] === "az_term" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {services[6] === "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {services[6] === "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {services[6] === "term"
                                                  ? "DID Termination"
                                                  : ""}
                                                {services[6] === "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {services[6] === "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {services[6] === "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {services[6] === "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {services[6] === "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {services[6] === "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                                {services[23] === "ratedeck"
                                                  ? " ( Rate Deck )"
                                                  : ""}
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              {services[23] === "ratedeck" ? (
                                                <>
                                                  {/*<li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Agent Commission
                                                      </span>{" "}
                                                      {services[14]}%
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services[24]}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Download Rate Deck
                                                      </span>{" "}
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </p>
                                                  </li>*/}
                                                </>
                                              ) : (
                                                <>
                                                  {services[28] !==
                                                  "unmetered" ? (
                                                    <li className="col-sm-4">
                                                      <p>
                                                        <span className="text-soft">
                                                          Rate Per Minute{" "}
                                                          <Tooltip
                                                            title="Rate assigned per minute."
                                                            placement="top"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          / Commission{" "}
                                                          <Tooltip
                                                            title="Commission amount for agent, taken out of profit."
                                                            placement="top"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>
                                                        </span>{" "}
                                                        ${services[7]} / $
                                                        {(
                                                          (services[7] -
                                                            services[16]) *
                                                          (services[14] / 100)
                                                        ).toFixed(2)}{" "}
                                                        ({services[14]}%)
                                                      </p>
                                                    </li>
                                                  ) : null}
                                                </>
                                              )}
                                              {services[28] === "unmetered" ? (
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Per Session{" "}
                                                      <Tooltip
                                                        title="Rate assigned per session."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services[7]} / $
                                                    {(
                                                      (services[7] -
                                                        services[32]) *
                                                      (services[14] / 100)
                                                    ).toFixed(2)}{" "}
                                                    ({services[14]}%)
                                                  </p>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "sms" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS In{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS In Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[10]} / $
                                                  {(
                                                    (services[10] -
                                                      services[15]) *
                                                    (services[17] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[17]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    SMS Out{" "}
                                                    <Tooltip
                                                      title="Price applied to SMS Out Services."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  ${services[11]} / $
                                                  {(
                                                    (services[11] -
                                                      services[16]) *
                                                    (services[18] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[18]}%)
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "cnam" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              {services[1]}{" "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                New
                                              </span>
                                            </h6>
                                          </div>

                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Update CNAM (MRC){" "}
                                                    <Tooltip
                                                      title="Price applied to Update CNAM (MRC)."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {services[33]
                                                    ? services[33].toFixed(4)
                                                    : "0.00"}{" "}
                                                  / $
                                                  {(
                                                    (services[33] -
                                                      services[36]) *
                                                    (services[39] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[39]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    CNAM DIP{" "}
                                                    <Tooltip
                                                      title="Price applied to CNAM DIP."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {services[34]
                                                    ? services[34].toFixed(4)
                                                    : "0.00"}{" "}
                                                  / $
                                                  {(
                                                    (services[34] -
                                                      services[37]) *
                                                    (services[41] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[41]}%)
                                                </p>
                                              </li>
                                              <li className="col-sm-4">
                                                <p>
                                                  <span className="text-soft">
                                                    Storage DIP{" "}
                                                    <Tooltip
                                                      title="Price applied to Storage DIP."
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>{" "}
                                                    / Commission{" "}
                                                    <Tooltip
                                                      title="Commission amount for agent, taken out of profit"
                                                      placement="top"
                                                    >
                                                      <em
                                                        className="icon ni ni-info"
                                                        style={{
                                                          color: "#6576ff",
                                                        }}
                                                      ></em>
                                                    </Tooltip>
                                                  </span>{" "}
                                                  $
                                                  {services[35]
                                                    ? services[35].toFixed(4)
                                                    : "0.00"}{" "}
                                                  / $
                                                  {(
                                                    (services[35] -
                                                      services[38]) *
                                                    (services[40] / 100)
                                                  ).toFixed(2)}{" "}
                                                  ({services[40]}%)
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[6] === "did" ||
                                      services[6] === "tfn" ||
                                      services[6] === "teams" ? (
                                        <>
                                          {services[30] &&
                                          services[30].length > 0 ? (
                                            <div className="card-inner">
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Porting Rates
                                                </h6>
                                              </div>

                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate / Spiff
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portin_sell_rate.toFixed(
                                                        4
                                                      )}{" "}
                                                      / $
                                                      {services[30][0].portin_agent_spiff.toFixed(
                                                        2
                                                      )}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Portin Sell Rate / Spiff
                                                      </span>{" "}
                                                      $
                                                      {services[30][0].portout_sell_rate.toFixed(
                                                        4
                                                      )}{" "}
                                                      / $
                                                      {services[30][0].portout_agent_spiff.toFixed(
                                                        2
                                                      )}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                      <div className="card-inner">
                                        {services[23] !== "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Cancellation Fee
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Cancellation Fee
                                                    </span>{" "}
                                                    {services[29] &&
                                                    services[29] !== "" ? (
                                                      <>
                                                        $
                                                        {services[29].toFixed(
                                                          4
                                                        )}
                                                      </>
                                                    ) : (
                                                      "$0.00"
                                                    )}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[23] === "ratedeck" ? (
                                          <>
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Rate Deck / Effective Date
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Rate Deck
                                                    </span>{" "}
                                                    {services[25] &&
                                                    services[25] !== "" ? (
                                                      <>
                                                        <a
                                                          onClick={() =>
                                                            this.downloadCsv(
                                                              services[25]
                                                            )
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="btn btn-outline-primary"
                                                        >
                                                          <em className="icon ni ni-download"></em>{" "}
                                                          Download Rate Deck
                                                        </a>
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      Effective Date
                                                    </span>{" "}
                                                    {services[24] &&
                                                    services[24] !== "" ? (
                                                      <>
                                                        {this.format_date(
                                                          services[24]
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ) : null}
                                        {services[31] &&
                                        services[31].length > 0 ? (
                                          <>
                                            <div
                                              className="sp-plan-head"
                                              style={{
                                                marginTop: "15px",
                                                borderTop: "1px solid #f6f6f6",
                                                paddingTop: "15px",
                                              }}
                                            >
                                              <h6 className="title">
                                                Miscellaneous Rates
                                              </h6>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <table className="table">
                                                <thead className="thead-light">
                                                  <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">
                                                      Rate Type
                                                    </th>
                                                    <th scope="col">Rate</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {services[31].map(
                                                    (miscrate, idx) => (
                                                      <tr key={`mis${idx}`}>
                                                        <td>
                                                          {miscrate.misc_title}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_type ===
                                                          "per_minute"
                                                            ? "Per Minute"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                          "per_hour"
                                                            ? "Per Hour"
                                                            : ""}
                                                          {miscrate.misc_type ===
                                                          "one_time"
                                                            ? "One Time"
                                                            : ""}
                                                        </td>
                                                        <td>
                                                          {miscrate.misc_rate !==
                                                            "" &&
                                                          miscrate.misc_rate >
                                                            0 ? (
                                                            <>
                                                              $
                                                              {miscrate.misc_rate.toFixed(
                                                                4
                                                              )}
                                                            </>
                                                          ) : (
                                                            "$0.00"
                                                          )}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                      <div className="card-inner">
                                        <div className="sp-plan-head">
                                          <h6 className="title">
                                            Add / Update Dates
                                          </h6>
                                        </div>
                                        <div className="sp-plan-desc sp-plan-desc-mb">
                                          <ul className="row gx-1">
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                  Date Added
                                                </span>{" "}
                                                {services[19] !== ""
                                                  ? this.format_date(
                                                      services[19]
                                                    )
                                                  : null}
                                              </p>
                                            </li>
                                            <li className="col-sm-5">
                                              <p>
                                                <span className="text-soft">
                                                  Date Updated
                                                </span>{" "}
                                                {services[20] !== ""
                                                  ? this.format_date(
                                                      services[20]
                                                    )
                                                  : null}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      {services[42] &&
                                      services[42] === "link" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Marketing Link
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Marketing Link
                                                  </span>{" "}
                                                  <a
                                                    href={services[43]}
                                                    target="_blank"
                                                  >
                                                    <em className="icon ni ni-link-h"></em>{" "}
                                                    Click to open link
                                                  </a>
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      {services[42] &&
                                      services[42] === "pdf" ? (
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Marketing Link
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Marketing Link
                                                  </span>{" "}
                                                  <a
                                                    href={services[43]}
                                                    target="_blank"
                                                  >
                                                    <em className="icon ni ni-file-pdf"></em>{" "}
                                                    View PDF
                                                  </a>
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="card-inner">
                                        <div className="sp-plan-head-group">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Short Description
                                            </h6>
                                            <span className="ff-italic text-soft">
                                              {services[3]}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}
                  {/* DEFAULT MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDefault"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Set Default</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.errorMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.errorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.successMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.successMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {this.state.waitMessage !== "" ? (
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-warning">
                                      <div className="alert-text">
                                        <h4>Waiting...</h4>
                                        <p>{this.state.waitMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="nk-modal-action"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DEFAULT MODAL End */}
                  {/* DETAILS MODAL */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDetailBundle"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Bundle
                              Details
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          <br />
                          {/* NRC DIVS START */}

                          {this.state.bundleDetails &&
                          this.state.bundleDetails.length > 0 ? (
                            <div className="card card-bordered">
                              <div className="card-inner-group">
                                <div className="card-inner">
                                  <div className="sp-plan-head">
                                    <h6 className="title">
                                      {this.state.bundleDetails[0].bundle_title}
                                    </h6>
                                  </div>
                                  <div
                                    className="sp-plan-desc sp-plan-desc-mb"
                                    style={{
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgb(246, 246, 246)",
                                      paddingBottom: "7px",
                                    }}
                                  >
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Per Month Rate (Charge Monthly){" "}
                                            <Tooltip
                                              title="Per Month Rate (Charge Monthly)"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </span>{" "}
                                          $
                                          {this.state.bundleDetails[0].per_month_rate.toFixed(
                                            4
                                          )}
                                        </p>
                                      </li>
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Per Month Rate (Charge Yearly)
                                            <Tooltip
                                              title="Per Month Rate (Charge Yearly)"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </span>{" "}
                                          $
                                          {this.state.bundleDetails[0].per_year_rate.toFixed(
                                            4
                                          )}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="sp-plan-desc sp-plan-desc-mb"
                                    style={{
                                      marginBottom: "15px",
                                      borderBottom:
                                        "1px solid rgb(246, 246, 246)",
                                      paddingBottom: "7px",
                                    }}
                                  >
                                    <ul className="row gx-1">
                                      <li className="col-sm-6">
                                        <p>
                                          <span className="text-soft">
                                            Date Added
                                            <Tooltip
                                              title="Date Added"
                                              placement="top"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>{" "}
                                          </span>{" "}
                                          {this.state.bundleDetails[0]
                                            .date_added !== ""
                                            ? this.format_date(
                                                this.state.bundleDetails[0]
                                                  .date_added
                                              )
                                            : null}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                  {this.state.bundleDetails[0]
                                    .marketing_content !== "" &&
                                  this.state.bundleDetails[0]
                                    .marketing_content === "link" ? (
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-6">
                                          <p>
                                            <span className="text-soft">
                                              Marketing Link
                                              <Tooltip
                                                title="Marketing Link"
                                                placement="top"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                            </span>{" "}
                                            <a
                                              href={
                                                this.state.bundleDetails[0]
                                                  .marketing_link !== ""
                                                  ? this.state.bundleDetails[0]
                                                      .marketing_link
                                                  : null
                                              }
                                              target="_blank"
                                            >
                                              <em className="icon ni ni-link-h"></em>{" "}
                                              Click to open link
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  ) : null}
                                  {this.state.bundleDetails[0]
                                    .marketing_content !== "" &&
                                  this.state.bundleDetails[0]
                                    .marketing_content === "pdf" ? (
                                    <div className="sp-plan-desc sp-plan-desc-mb">
                                      <ul className="row gx-1">
                                        <li className="col-sm-6">
                                          <p>
                                            <span className="text-soft">
                                              Marketing PDF{" "}
                                              <Tooltip
                                                title="Marketing PDF"
                                                placement="top"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                            </span>{" "}
                                            <a
                                              href={
                                                this.state.bundleDetails[0]
                                                  .marketing_link !== ""
                                                  ? this.state.bundleDetails[0]
                                                      .marketing_link
                                                  : null
                                              }
                                              target="_blank"
                                            >
                                              <em className="icon ni ni-file-pdf"></em>{" "}
                                              View PDF
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <h4
                            style={{ marginTop: "20px", mrginBottom: "20px" }}
                          >
                            Assigned Services
                          </h4>

                          {this.state.bundleServiceDetails &&
                          this.state.bundleServiceDetails.length > 0 ? (
                            <>
                              {this.state.bundleServiceDetails.map(
                                (bundle_services, idk) => (
                                  <div
                                    className="nk-block"
                                    key={`bundle${idk}`}
                                  >
                                    <div className="card card-bordered">
                                      <div className="card-inner">
                                        <div className="nk-help">
                                          <div className="nk-help-img">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 90 90"
                                            >
                                              <path
                                                d="M63.42,87H10.58A4.08,4.08,0,0,1,6.5,82.92V7.08A4.08,4.08,0,0,1,10.58,3H48.32L67.5,21.23V82.92A4.08,4.08,0,0,1,63.42,87ZM47.5,3V16.92A4,4,0,0,0,51.38,21H66.5"
                                                fill="#fff"
                                                stroke="#6576ff"
                                                strokeLinecap="round"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></path>
                                              <circle
                                                cx="63.5"
                                                cy="67"
                                                r="20"
                                                fill="#6576ff"
                                              ></circle>
                                              <path
                                                d="M61.87,71a6.83,6.83,0,0,1,.39-2.55,6.71,6.71,0,0,1,1.51-2.09,11.82,11.82,0,0,0,1.44-1.61,2.92,2.92,0,0,0,.47-1.59,2.47,2.47,0,0,0-.55-1.72,2,2,0,0,0-1.58-.6,2.22,2.22,0,0,0-1.61.59A2,2,0,0,0,61.33,63H58.5a4.39,4.39,0,0,1,1.4-3.37,5.27,5.27,0,0,1,3.65-1.24,5.09,5.09,0,0,1,3.64,1.23,4.48,4.48,0,0,1,1.31,3.43,5.69,5.69,0,0,1-1.77,3.86L65.3,68.37A4.08,4.08,0,0,0,64.51,71Zm-.3,3.17A1.6,1.6,0,0,1,62,73,1.69,1.69,0,0,1,65,74.17a1.65,1.65,0,0,1-.44,1.17,1.67,1.67,0,0,1-1.26.46A1.62,1.62,0,0,1,62,75.34,1.65,1.65,0,0,1,61.57,74.17Z"
                                                fill="#fff"
                                              ></path>
                                              <circle
                                                cx="17"
                                                cy="21.5"
                                                r="4.5"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></circle>
                                              <line
                                                x1="28.5"
                                                y1="20"
                                                x2="36.5"
                                                y2="20"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></line>
                                              <line
                                                x1="28.5"
                                                y1="24"
                                                x2="43.5"
                                                y2="24"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></line>
                                              <circle
                                                cx="17"
                                                cy="39.5"
                                                r="4.5"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></circle>
                                              <line
                                                x1="28.5"
                                                y1="37"
                                                x2="36.5"
                                                y2="37"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></line>
                                              <line
                                                x1="28.5"
                                                y1="42"
                                                x2="43.5"
                                                y2="42"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></line>
                                              <circle
                                                cx="17"
                                                cy="56.5"
                                                r="4.5"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></circle>
                                              <line
                                                x1="28.5"
                                                y1="54"
                                                x2="36.5"
                                                y2="54"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></line>
                                              <line
                                                x1="28.5"
                                                y1="59"
                                                x2="43.5"
                                                y2="59"
                                                fill="none"
                                                stroke="#6576ff"
                                                strokeMiterlimit="10"
                                                strokeWidth="2"
                                              ></line>
                                            </svg>
                                          </div>
                                          <div className="nk-help-text">
                                            <h5>
                                              {bundle_services.service_title}
                                              {"  "}
                                              <span className="badge badge-dim badge-primary badge-pill">
                                                {bundle_services.service_type ===
                                                "did"
                                                  ? "DID Origination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "tfn"
                                                  ? "Toll Free Origination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "term"
                                                  ? "DID Termination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "teams"
                                                  ? "Microsoft Teams UCAAS"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "ivr"
                                                  ? "Voyce Admins"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "tfn_term"
                                                  ? "Toll Free Termination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "az_term"
                                                  ? "International A-Z Termination"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "live_agent"
                                                  ? "Live Agent"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "sms"
                                                  ? "SMS Inbound / Outbound"
                                                  : ""}
                                                {bundle_services.service_type ===
                                                "cnam"
                                                  ? "CNAM"
                                                  : ""}
                                                {bundle_services.main_type ===
                                                "ratedeck"
                                                  ? " ( Rate Deck )"
                                                  : ""}
                                              </span>
                                            </h5>
                                            <p className="text-soft"></p>
                                          </div>
                                          <div className="nk-help-action">
                                            <a
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.stopAndShowService(
                                                  bundle_services.id,
                                                  this.state.bundleDetails[0].id
                                                )
                                              }
                                              className="btn btn-lg btn-outline-primary"
                                            >
                                              Go To Service
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAILS MODAL */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
