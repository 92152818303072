import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { checkLogin } from "../../../config/client_api_calls";

import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";
import { tableLoaderSimple } from "../../../components/ContentLoaders/table_loader_simple";
import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      email: "",
      password: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
      resellerData: [],
      tableLoader: true,
      tableLoaderSimple: true,
      res_accountno: "",
      res_url: "",
      res_hash: "",
      is_agent_selected: true,
      res_title: "",
      pageError: "",
      passwordShown: false,
      url_type: "Simple",
    };
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;

    this.setState({
      tableLoaderSimple: true,
    });

    let urlElements = window.location.href.split("/");

    console.log("CUrrent URL: ", reseller_url);
    let host_url = urlElements[2];
    //if (reseller_url !== "") {
    //Errors
    //404 and no_reseller
    //404 and no_agent
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/check_reseller_login",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            host_url: host_url,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("response.data start : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessage: "",
              show_form: false,
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
              tableLoaderSimple: false,
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessage: "",
              show_form: false,
              tableLoaderSimple: false,
              pageError: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            console.log("response.data.url_type: ", response.data.url_type);
            Cookies.set(
              "residentifier__",
              CryptoJS.AES.encrypt(
                response.data.reseller_url,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "resacct__",
              CryptoJS.AES.encrypt(
                response.data.res_accountno,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "restitle__",
              CryptoJS.AES.encrypt(
                response.data.reseller_title,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "reslogo__",
              CryptoJS.AES.encrypt(
                response.data.reseller_logo,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "ressig__",
              CryptoJS.AES.encrypt(
                response.data.reseller_signature,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            Cookies.set(
              "urltype__",
              CryptoJS.AES.encrypt(
                response.data.url_type,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );

            this.setState({
              errorMessage: "",
              res_accountno: response.data.res_accountno,
              res_title: response.data.reseller_title,
              resellerData: response.data,
              tableLoader: false,
              show_form: true,
              pageError: "",
              res_url: reseller_url,
              res_hash: response.data.reseller_hash,
              tableLoaderSimple: false,
              url_type: response.data.url_type,
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
    /*} else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoaderSimple: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }*/
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown,
    });
  };

  formSubmitClient = async (e) => {
    e.preventDefault();
    let { email, password } = this.state;

    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else if (password === "") {
      return this.setState({
        errorPass: "error",
        errorEmail: "",
        errorMessage: "Password is required.",
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        disabled: true,
        loader: "show",
      });

      try {
        const response = await checkLogin(email, password);
        console.log("clientlogin", response.data);
        if (
          response.data.status === 404 &&
          response.data.message === "invalid_credentials"
        ) {
          this.setState({
            errorMessage: response.data.errors,
            disabled: false,
          });
        } else if (
          response.data.status === 404 ||
          response.data.status === 422 ||
          response.data.errors !== ""
        ) {
          this.setState({
            errorMessage: response.data.errors,
            disabled: false,
          });
        }

        if (
          response.data.status === 200 &&
          response.data.message === "success"
        ) {
          this.setState({
            successMessage: "Please wait, redirecting you to the dashboard.",
            errorMessage: "",
          });
          Cookies.set(
            "client_tkn__",
            CryptoJS.AES.encrypt(
              response.data.token,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_acn__",
            CryptoJS.AES.encrypt(
              response.data.accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_eml__",
            CryptoJS.AES.encrypt(
              response.data.email,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_comp_nme__",
            CryptoJS.AES.encrypt(
              response.data.comp_name,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_user_type__",
            CryptoJS.AES.encrypt(
              response.data.user_type,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_parent_accountno__",
            CryptoJS.AES.encrypt(
              response.data.parent_accountno,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_name__",
            CryptoJS.AES.encrypt(
              response.data.name,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_phone__",
            CryptoJS.AES.encrypt(
              response.data.phone,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_adress__",
            CryptoJS.AES.encrypt(
              response.data.address,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );
          Cookies.set(
            "client_title__",
            CryptoJS.AES.encrypt(
              response.data.title,
              process.env.REACT_APP_SIGNATURE
            ).toString()
          );

          setTimeout(function () {
            //this.props.history.push("/dashboard");
            //  window.location = "/dashboard";
            window.location.replace(
              auth.getResellerRegToken() + "/client/dashboard"
            );
          }, 1000);
        }
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  render() {
    if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }
    if (auth.isAdminLogin() === true) {
      return <Redirect to="/admin_dashboard" />;
    }
    if (auth.isClientLogin() === true) {
      return (
        <Redirect to={"/" + auth.getResellerRegToken() + "/client/dashboard"} />
      );
    }
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          {/* <div className="progress progress-sm">
            <div className="progress-bar" data-progress="95"></div>
          </div> */}
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn-white btn btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  {auth.getResellerLogo() && auth.getResellerLogo() !== "" ? (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={auth.getResellerLogo()}
                        alt="logo-dark"
                      />
                    </a>
                  ) : (
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo-dark"
                      />
                    </a>
                  )}
                </div>

                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Sign-In</h5>

                    <div className="nk-block-des">
                      <p>Use your verified email and password.</p>

                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      {/* <p
                        style={{
                          color: "red",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        {this.state.errorMessage}
                      </p> */}
                    </div>
                  </div>
                </div>
                <ul className="nav nav-tabs">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabItem5"
                    >
                      <em className="icon ni ni-signin"></em>
                      <span>Agent Login</span>
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabItem6"
                    >
                      <em className="icon ni ni-signin"></em>
                      <span>Client Login</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {/* <div className="tab-pane active" id="tabItem5">
                    {this.state.pageError !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.pageError}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {this.state.tableLoaderSimple === true ? (
                          tableLoaderSimple()
                        ) : (                        
                          <form
                            method="post"
                            className="loginForm"
                            onSubmit={this.formSubmit}
                          >
                            <div className="form-group">
                              <div className="form-label-group">
                                <label
                                  className="form-label"
                                  htmlFor="default-01"
                                >
                                  Email
                                </label>                                
                              </div>
                              <input
                                type="email"
                                className={
                                  "form-control form-control-lg " +
                                  this.state.errorEmail
                                }
                                id="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                placeholder="Enter your email address"
                              />
                            </div>
                            <div className="form-group">
                              <div className="form-label-group">
                                <label
                                  className="form-label"
                                  htmlFor="password"
                                >
                                  Password
                                </label>
                                <a
                                  className="link link-primary link-sm"
                                  tabIndex="-1"
                                  href="/forgot"
                                  style={{ color: "#006393" }}
                                >
                                  Forgot Password?
                                </a>
                              </div>
                              <div className="form-control-wrap">
                                <a
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  tabIndex="-1"
                                  onClick={() => {
                                    this.togglePassword();
                                  }}
                                  className="form-icon form-icon-right passcode-switch"
                                  data-target="password"
                                >
                                  {this.state.passwordShown ? (
                                    <em className="icon ni ni-eye-off"></em>
                                  ) : (
                                    <em className="icon ni ni-eye"></em>
                                  )}
                                </a>
                                <input
                                  type={
                                    this.state.passwordShown
                                      ? "text"
                                      : "password"
                                  }
                                  className={
                                    "form-control form-control-lg " +
                                    this.state.errorPass
                                  }
                                  id="password"
                                  name="password"
                                  onChange={this.handleChange}
                                  value={this.state.password}
                                  placeholder="Enter your password"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-lg btn-block"
                                disabled={this.state.disabled}
                                type="submit"
                                style={{
                                  backgroundColor: THEME_COLOR,
                                  color: "white",
                                }}
                              >
                                Sign in
                              </button>
                            </div>
                          </form>
                        )}
                      </>
                    )}
                  </div> */}
                  <div className="tab-pane active" id="tabItem6">
                    {this.state.pageError !== "" ? (
                      <div className="example-alert">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>Error</h4>
                            <p>{this.state.pageError}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {this.state.tableLoaderSimple === true ? (
                          tableLoaderSimple()
                        ) : (
                          <form
                            method="post"
                            className="loginForm"
                            onSubmit={this.formSubmitClient}
                          >
                            <div className="form-group">
                              <div className="form-label-group">
                                <label
                                  className="form-label"
                                  htmlFor="default-01"
                                >
                                  Email
                                </label>
                                {/* <a
                        className="link link-primary link-sm"
                        tabIndex="-1"
                        href="#"
                      >
                        Need Help?
                      </a> */}
                              </div>
                              <input
                                type="email"
                                className={
                                  "form-control form-control-lg " +
                                  this.state.errorEmail
                                }
                                id="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                placeholder="Enter your email address"
                              />
                            </div>
                            <div className="form-group">
                              <div className="form-label-group">
                                <label
                                  className="form-label"
                                  htmlFor="password"
                                >
                                  Password
                                </label>
                                <a
                                  className="link link-primary link-sm"
                                  tabIndex="-1"
                                  href={`/${this.state.res_url}/client/forgot`}
                                  style={{ color: "#006393" }}
                                >
                                  Forgot Password?
                                </a>
                              </div>
                              <div className="form-control-wrap">
                                <a
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  tabIndex="-1"
                                  onClick={() => {
                                    this.togglePassword();
                                  }}
                                  className="form-icon form-icon-right passcode-switch"
                                  data-target="password"
                                >
                                  {this.state.passwordShown ? (
                                    <em className="icon ni ni-eye-off"></em>
                                  ) : (
                                    <em className="icon ni ni-eye"></em>
                                  )}
                                </a>
                                <input
                                  type={
                                    this.state.passwordShown
                                      ? "text"
                                      : "password"
                                  }
                                  className={
                                    "form-control form-control-lg " +
                                    this.state.errorPass
                                  }
                                  id="password"
                                  name="password"
                                  onChange={this.handleChange}
                                  value={this.state.password}
                                  placeholder="Enter your password"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <button
                                className="btn btn-lg btn-block"
                                disabled={this.state.disabled}
                                type="submit"
                                style={{
                                  backgroundColor: THEME_COLOR,
                                  color: "white",
                                }}
                              >
                                Sign in
                              </button>
                            </div>
                          </form>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/*<div className="form-note-s2 pt-4">
                  {" "}
                  New on our platform?{" "}
                  {auth.getUrlType() === "Simple" ? (
                    <a
                      href={`/client/register/${auth.getResellerRegToken()}/${auth.getClientService()}/${auth.getClientServiceType()}`}
                    >
                      Create an account
                    </a>
                  ) : (
                    <a href={`${this.state.res_hash}/register`}>
                      Create an account
                    </a>
                  )}
                  </div>*/}
              </div>
              <div className="nk-block nk-auth-footer">
                {/* <div className="nk-block-between">
                  <ul className="nav nav-sm">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Terms & Condition
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Help
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="mt-3">
                  <p>
                    &copy; {new Date().getFullYear()}{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>
                          {auth.getResellerRegTitle()} Agents / Channel Partners
                        </h4>
                        <p>
                          Enter your details here to login in to our system.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_B} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          You can start to create your products easily with its
                          user-friendly design & most completed responsive
                          layout.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_C} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          You can start to create your products easily with its
                          user-friendly design & most completed responsive
                          layout.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
